const genres = {
  "067044d8-9c45-49d8-9ece-2bb4857928d4": {
      "name": "Acoustic",
      "slug": "acoustic",
      "description": "Acoustic music is the definition of calm! With natural sounds from guitars, pianos, and gentle percussion, it creates a soothing, heartfelt vibe. Think of cozy vibes, intimate settings, and that genuine connection. It's ideal for brands wanting to showcase their down-to-earth side, like eco-friendly products, handcrafted goods, or anything with a serene or romantic touch. Best suited for ads that highlight sustainability, product demos with a personal feel, or any time you want to create a sense of trust and sincerity. And the best part? Our collection of popular Bollywood melodies is 100% copyright-safe and easy to license so you can add that acoustic warmth to your brand videos without any worries. It's a stairway to make your content more relatable and emotionally resonant!"
  },
  "4b2a7fca-d34b-4baa-972b-03086b86a0ff": {
      "name": "Afro",
      "slug": "afro",
      "description": "You won’t be able to stop your feet moving when Afro music plays! It’s all about infectious beats, groovy basslines, and vibrant melodies that make you want to move. Think sun-soaked vibes, rich African heritage, and an energy that’s hard to ignore. Best suited for brands that want to bring boldness, joy, and cultural flair to their content—whether it’s for travel promos, fashion campaigns, or feel-good product launches. Afro-music sets the perfect mood for high-energy ads, vibrant lifestyle content, or anything that needs an extra dose of rhythm and fun. Want some popular groovy beats? You can get Bollywood chart-busters that are copyright-safe and super easy to license on our platform, so you can spice up your short-form promotional videos with Afro beats that bring the heat—without licensing headaches."
  },
  "6612f3b1-8745-4305-afe9-acd973f7cecf": {
      "name": "Ambient",
      "slug": "ambient",
      "description": "Ambient music is the sound of serenity! Soft, flowing, and atmospheric, it creates a calm and immersive mood, great for relaxation, mindfulness, and elegant branding. Think dreamy soundscapes, gentle synths, and soothing textures—ideal for wellness brands, spa promotions, corporate videos, and tech showcases. Whether you’re looking to create a peaceful backdrop for storytelling or an inspiring feel for your visuals, ambient music brings effortless sophistication. It’s ideal for brands that value simplicity, clarity, and emotional depth. Can you license ambient tracks without hassle? Absolutely! Our collection of trendy Bollywood tunes are copyright-free so you can enhance your short-form promotional videos with beautiful, ethereal soundscapes."
  },
  // "8be0c3e5-1f2a-4f1e-98e8-494abe02406f": {
  //     "name": "Blues",
  //     "slug": "blues",
  //     "description": "Soulful guitar riffs and emotional melodies that bring depth to character-driven stories and vintage narratives."
  // },
  "3e3cb3f5-fcf0-4e4d-a5b9-ea1d6c2cf4cc": {
      "name": "Bollywood",
      "slug": "bollywood",
      "description": "Bollywood music is a total vibe! It's an amazing mix of Indian classical, folk, and pop, all wrapped in a dramatic package. Think catchy tunes, big orchestras, and that signature Bollywood flair. It's perfect for brands wanting to capture the Indian spirit and heart. Imagine using this music for festive ads, product launches with a desi touch, or any time you want to connect with Indian audiences on an emotional level. Need some Bollywood spice? Our collection of trendy chart-toppers is 100% copyright-safe and easy to license so you can add that Bollywood magic to your brand videos without any worries. It's a surefire way to make your content more engaging and visually rich!"
  },
  "478cf343-37bc-4458-b596-5de8c0edcddb": {
      "name": "Cinematic",
      "slug": "cinematic",
      "description": "What could showcase grandeur better than cinematic music? With sweeping orchestras, intense percussion, and grand melodies, it transforms any video into a blockbuster experience. Think of inspiring trailers, powerful brand storytelling, and high-impact visuals. Whether you’re launching an ethnic wear campaign, an emotional regional food video, or an action-packed promo, cinematic music adds depth and intensity. It’s ideal for brands that want to evoke passion, excitement, or even mystery. Ready to add a touch of prestige to your content? With our collection of 100% copyright-safe, trending Bollywood anthems and easy-to-license music, you can make promotional videos without any hassle. If your brand is about making a statement, cinematic music is the way to go!"
  },
  // "fa082a08-94f8-48f1-ae33-35c1194baa77": {
  //     "name": "Corporate",
  //     "slug": "corporate",
  //     "description": "Upbeat and motivational tracks with polished tones, ideal for presentations, explainer videos, and success stories."
  // },
  // "ea5d8e0a-c5d9-41b9-a71d-dfab7087ed76": {
  //     "name": "Country",
  //     "slug": "country",
  //     "description": "Rustic melodies with acoustic charm, great for nature-inspired content, travelogues, and lifestyle projects."
  // },
  "ea7468e8-b5ef-46d9-ace6-9d10e5fc5760": {
      "name": "Devotional",
      "slug": "devotional",
      "description": "Devotional music is all about peace, spirituality, and deep connection. Featuring soothing chants, meditative rhythms, and traditional instruments, it brings a sense of calm and positivity. Think temple bells, soulful bhajans, and heartwarming choral harmonies—perfect for brands that focus on mindfulness, wellness, or spiritual content. It’s great for meditation apps, yoga studios, cultural documentaries, and festival promotions. Whether you want to create an atmosphere of devotion or simply add a touch of serenity to your content, devotional music is your go-to, but worried about copyright issues? Our collection of popular Bollywood melodies is 100% copyright-safe and easy to license so you can use it in your short-form promotional videos without any worries. Let your brand radiate peace, positivity, and purpose!"
  },
  "ef5b24a7-702b-462f-bf80-29cae8a6dbc7": {
      "name": "Electronic",
      "slug": "electronic",
      "description": "Electronic music is sleek, modern, and full of energy! Packed with pulsating beats, synth-driven melodies, and futuristic sounds, it’s great for brands that want to feel cutting-edge and dynamic. Think tech promos, fashion ads, gaming content, or fast-paced product launches. Whether it’s deep house, synth-wave, or high-energy EDM, electronic music adds an electrifying touch to your visuals. It’s the go-to genre for brands looking to create hype, excitement, and a stylish, contemporary feel. Need electronic music that's quick to license? Our collection of high-energy Bollywood beats is 100% copyright-safe and easy to license, so you can power up your short-form promotional videos with high-energy electronic vibes—no legal headaches, just pure sonic innovation."
  },
  "62a33aa8-4133-4084-9eac-05ecd702eb44": {
      "name": "Festival",
      "slug": "festival",
      "description": "Festival music is all about celebration, energy, and good vibes! Whether it’s pulsating dance beats, lively folk tunes, or cultural anthems, this genre is made for brands that want to bring joy and excitement to their content. Think big holiday sales, carnival-themed campaigns, or anything that screams fun and festivity! Perfect for event promos, brand launches, or high-spirited ads that need an extra burst of enthusiasm. Whether it’s Diwali, Christmas, or a summer music fest, festival music makes your content come alive. Need a festive soundtrack? Our collection of Bollywood firecracker melodies is 100% copyright-safe and easy to license so you can spread the festive cheer in your short-form promotional videos without any licensing stress."
  },
  "33c990ad-4298-4a07-a19b-93b148772e28": {
      "name": "Funk",
      "slug": "funk",
      "description": "Funk music is the salt bae of music genres! It's a killer blend of energetic rhythms and infectious basslines guaranteed to get you moving. Think retro vibes, a touch of cool, and a whole lot of funk. It's best suited for brands looking for some vintage swagger or targeting a nostalgic audience. Ideal for fashion ads with a retro twist, lifestyle product showcases with a playful vibe, or any brand wanting to add fun and soul to their image. The catch? There is none! Our popular Bollywood funk tracks are 100% copyright-safe and super easy to license so you can add that funky flavor to your brand videos without any worries. It's a surefire way to make your content more engaging and groovy!"
  },
  "6a79250a-2be5-43e9-a6ba-e094dfc46120": {
      "name": "Hip Hop / Rap",
      "slug": "hip-hop-rap",
      "description": "Hip-hop and rap are all about attitude, storytelling, and rhythm! With bold beats, smooth flows, and high-energy vibes, this genre gives your brand an edge. Think urban culture, creative expression, and a confident, modern feel. Best suited for fashion brands, tech promos, sports content, and social media campaigns that need a punchy, trendy sound. Hip-hop brings instant credibility and coolness, whether it’s old-school boom-bap or sleek trap beats. Want to add rap music to your videos? Our collection of trending dance anthems is 100% copyright-safe and easy to license so you can add that hip-hop swagger to your short-form promotional videos—no copyright strikes, just pure vibes. Time to turn up the volume!"
  },
  "82e9113f-c1a4-492b-adf9-8e292c7f4a9a": {
      "name": "Indian Classical",
      "slug": "indian-classical",
      "description": "Indian classical music is timeless, soulful, and deeply expressive. With intricate ragas, mesmerizing rhythms, and traditional instruments like the sitar and tabla, it brings depth and elegance to any content. Think cultural documentaries, luxury branding, yoga and meditation promos, or anything that calls for an authentic, sophisticated Indian touch. Whether you want to create a serene, meditative atmosphere or celebrate Indian heritage, classical music adds richness to your brand. And the best part? Our collection of super hit Bollywood melodies is 100% copyright-safe and easy to license so you can enhance your short-form promotional videos with the soul-stirring beauty of Indian classical sounds."
  },
  "55c32e7e-3214-405f-9196-356cbdcbfa64": {
      "name": "Indian Contemporary",
      "slug": "indian-contemporary",
      "description": "Indian contemporary music is a fusion of tradition and modernity! Blending elements of Bollywood, indie, and global influences it creates a fresh, stylish, and dynamic sound. Think vibrant ad campaigns, travel videos, or youth-focused branding that needs an upbeat yet culturally rooted touch. Whether you’re launching a new product, telling a heartfelt story, or making content for young, urban audiences, Indian contemporary music delivers the perfect balance of melody and energy. And the catch? None. Our collection of chart topper Bollywood hits is 100% copyright-safe and super duper easy to license so you can enhance your short-form promotional videos with effortlessly cool and culturally rich soundtracks—authentic, modern, and full of life!"
  },
  "0c29a125-b83e-4b4b-8208-ac8b7e810e2b": {
      "name": "Indian Folk",
      "slug": "indian-folk",
      "description": "Indian folk music is raw, vibrant, and full of cultural heritage! With earthy rhythms, soulful vocals, and traditional instruments like the dhol, flute, and ektara, it brings an authentic, storytelling vibe. Best suited for brands that celebrate roots, tradition, and community—whether it’s travel documentaries, rural initiatives, festive campaigns, or cultural storytelling. It adds warmth, nostalgia, and a deep emotional connection to your content. Looking for music that will elevate your content? Our collection of regional Bollywood hits is 100% copyright-safe and easy to license so you can bring the spirit of Indian folk to your short-form promotional videos—no hassle, just pure, authentic sound. Let your brand tell a story that resonates!"
  },
  // "b34273af-0f15-468c-befd-2f4dbff21f00": {
  //     "name": "Indian Folk Fusion",
  //     "slug": "indian-folk-fusion",
  //     "description": "A blend of traditional folk and modern beats, perfect for festive videos and vibrant cultural showcases."
  // },
  "63d98bbb-3754-4366-970b-f7cb1b230b59": {
      "name": "Indie",
      "slug": "indie",
      "description": "Indie music is all about fresh, feel-good vibes! With laid-back guitars, dreamy vocals, and an effortlessly cool aesthetic, it’s great for brands that want to feel authentic, creative, and personal. Think trendy lifestyle brands, artsy product promos, and heartfelt storytelling. Whether it’s a casual behind-the-scenes video, a stylish fashion shoot, or an emotional ad campaign, indie music sets the mood with charm and originality. It’s the sound of individuality, self-expression, and modern creativity. Need music that's quick to license? Our collection of trendy regional tunes is 100% copyright-safe and easy to license, so you can add that indie magic to your short-form promotional videos."
  },
  "8462b1d8-6210-4a61-9174-76a3f1a1af99": {
      "name": "Jazz",
      "slug": "jazz",
      "description": "Jazz is smooth, stylish, and endlessly cool! From sultry saxophones to lively swing rhythms, it’s perfect for brands that want to exude elegance, class, and a touch of nostalgia. Think luxury product promos, chic fashion campaigns, cozy café vibes, and anything that needs sophistication. Whether you’re aiming for an intimate, romantic feel or a lively, playful energy, jazz music brings timeless charm to your content. And the best part? Our collection of soulful Bollywood tracks is 100% copyright-safe and easy to license so you can enhance your short-form promotional videos with the smooth sounds of jazz."
  },
  "31026e90-b30c-4b15-ae87-be7fbd924b1a": {
      "name": "Kids",
      "slug": "kids",
      "description": "Kids' music is pure fun, energy, and imagination! With bouncy melodies, playful sounds, and sing-along vibes, it’s ideal for brands that want to engage young audiences. Think educational videos, toy ads, kids’ apps, and family-friendly content that needs a cheerful, lighthearted touch. Whether it’s animated storytelling, interactive learning, or a bright and bubbly promo, kids' music brings joy and excitement. The cherry on top? Our collection of trendy Bollywood tunes is fully copyright-safe and hassle-free to license, making your short-form videos shine. with music that’s popular and delightful! Time to make your content a hit with the little ones!"
  },
  "20129102-87b0-42b0-932d-5120cc2e5ace": {
      "name": "Latin",
      "slug": "latin",
      "description": "Latin music is fiery, passionate, and full of life! With infectious rhythms, bold brass sections, and irresistible beats, it instantly brings energy and movement to your content. Think salsa, reggaeton, or bossa nova—great for travel brands, dance promos, fashion campaigns, or anything that needs a little Latin spice. Whether you’re creating a sizzling summer ad or a high-energy brand launch, Latin music brings the heat. Guess what? You can use our vibrant, popular tracks worry-free—100% copyright-safe and ready to license for your promo videos with vibrant Latin vibes—no stress, just pure rhythm and fun!"
  },
  "109d19cd-02d1-4f8d-b5c7-81d073db6a62": {
      "name": "Pop",
      "slug": "pop",
      "description": "Pop music is catchy, upbeat, and made to stand out! With bright melodies, infectious hooks, and feel-good energy, it’s ideal for brands that want to grab attention and leave an impact. Think trendy fashion ads, viral social media campaigns, youthful product launches, and anything that needs a modern feel. Whether it’s a fun lifestyle promo or an inspiring brand story, pop music makes your content instantly engaging. No legal drama here! Our Bollywood beats are totally copyright-safe and simple to license, so your short-form content stays stress-free with vibrant pop sounds."
  },
  "2d67bd5d-353b-47dc-8373-0e60d3ce7970": {
      "name": "Retro",
      "slug": "retro",
      "description": "Retro music is all about nostalgia, vintage charm, and timeless cool! Whether it’s groovy ’70s disco, electrifying ’80s synth, or rockin’ ’90s vibes, retro music brings back the best of past decades. Best suited for brands that love a throwback moment—think fashion campaigns, classic car commercials, or fun, quirky content that plays with nostalgia. It’s great for adding personality and a sense of familiarity to your brand’s storytelling. The best news? You can use these golden Bollywood tunes worry-free—they’re copyright-safe and simple to license so you can add that retro magic to your short-form promotional videos."
  },
  "13d50cdd-6fcc-4537-b415-fd4dc28423be": {
      "name": "RnB",
      "slug": "rnb",
      "description": "RnB is smooth, soulful, and full of groove! With silky vocals, deep basslines, and laid-back beats, it’s perfect for brands that want to exude confidence, intimacy, and style. Think luxury fashion promos, romantic ads, lifestyle branding, or sleek product reveals. Whether it’s modern trap-soul or classic RnB, this genre sets the mood for cool, sophisticated content. And the best part? Our collection of trending Bollywood chart toppers is 100% copyright-safe and easy to license, so you can enhance your short-form promotional videos with sultry, stylish RnB sounds—smooth, elegant, and effortlessly cool."
  },
  "747af598-6978-440e-a1c7-932a9d185d11": {
      "name": "Rock",
      "slug": "rock",
      "description": "Rock music is bold, rebellious, and packed with energy! With roaring guitars, pounding drums, and electrifying riffs, it’s great for brands that want to make a statement. Think edgy fashion, high-performance sports, or adrenaline-fueled action content. Whether it’s classic rock, punk, or modern alt-rock, this genre brings power and excitement to your brand’s storytelling. It’s the sound of confidence, attitude, and pushing boundaries. Here’s the kicker: Our collection of Bollywood bangers is legally sound, copyright-safe, and super easy to license for your videos! Add some serious rock energy to your social media promotional videos."
  },
  "063115a1-b198-4095-9054-1c4d4359cd73": {
      "name": "Western Classical",
      "slug": "western-classical",
      "description": "Western classical music is elegance, grandeur, and timeless sophistication! With majestic orchestras, delicate piano pieces, and dramatic symphonies, it adds depth and class to any content. Think luxury branding, historical documentaries, or cinematic storytelling that needs a refined touch. Whether it’s a graceful waltz or an intense symphony, classical music makes your brand feel premium and polished. And the best part? What makes it even better? These popular Bollywood melodies are 100% copyright-safe and available for effortless licensing. so you can elevate your short-form promotional videos with the beauty of classical music—graceful, powerful, and truly timeless!"
  },
  "403989c0-f71f-4b00-b534-d2fe4f0af1b9": {
      "name": "World",
      "slug": "world",
      "description": "World music is diverse, colorful, and full of global influences! From African drumming to Asian melodies and Middle Eastern rhythms, this genre brings cultures together through sound. Ideal for travel content, cultural storytelling, heritage brands, and unique brand identities. Whether you’re showcasing a global product, an inspiring documentary, or a vibrant celebration, world music adds an authentic and immersive touch. The best news? You can use these popular Bollywood tunes worry-free—they’re copyright-safe and simple to license! Bring global sounds to your short-form promotional videos—rich, dynamic, and full of cultural flavor!"
  }
}

export default genres;