import React, { useEffect, useState } from "react";
import "../styles/TrackListing.css";
import config from "../utils/config";
import { FaPlay } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
import CustomButton from "../components/CustomButton/CustomButton";
import { RiShoppingCart2Line } from "react-icons/ri";
import AllTrackCards from "../components/CardsComponent/TrackCard/AllTrackCards";
import { Link } from "react-router-dom";
import RandomDefaultImage from "../utils/RandomDefaultImage";
import setMetaTags from "../utils/SeoService";
import { MktCards } from "../utils/MktCards";
import GetMktCard from "../utils/GetMktCard";
import TrackCard from "../components/CardsComponent/TrackCard/TrackCard";
import {
  remoteConfig,
  fetchAndActivate,
  getValue,
} from "../utils/firebase/remoteConfig";
import randomDefaultImage from "../utils/RandomDefaultImage";
import getDirectLink from "../utils/AudioUrlFormated";
import { getMultiTrack } from "../service/TrackService";

const TrackListing = () => {
  const [popularTracks, setPopularTracks] = useState([]); // popular tracks data
  useEffect(() => {
    const seoData = {
      title: "Track Based Music Licensing | Hoopr Smash",
      description:
        "Find the perfect track for your project in our extensive library of premium Bollywood music, categorized by mood, genre, and budget to suit any creative need.",
      ogImage: `https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/promotional/tracks/listing1.webp`,
      canonical: window.location.href,
      // lang: "en",
    };
    setMetaTags(seoData);
  }, []);

  // fetching the remote config file from the firebase
  useEffect(() => {
    const fetchRemoteConfig = async () => {
      try {
        // Fetch and activate remote config values
        await fetchAndActivate(remoteConfig); // This waits for the config to be fetched

        // Retrieve the JSON string from Remote Config
        const jsonString = getValue(remoteConfig, "Home_Config").asString();

        // Parse the JSON string into a JavaScript object
        const parsedConfig = JSON.parse(jsonString);
        // console.log("Parsed Config:", parsedConfig);
        // console.log("Tracks Data:", parsedConfig.tracklisting);
        processTracks(parsedConfig.tracklisting);
      } catch (error) {
        console.error("Error fetching remote config:", error);
      }
    };

    fetchRemoteConfig();
  }, []);

  // Process the tracks and filter by type
  const processTracks = (tracklisting) => {
    // console.log(tracklisting, "tracklisting");

    const getTracks = tracklisting.find((item) => item.track); // Finds the object that has "track"
    let trackCodes = "";
    if (getTracks && getTracks.track) {
      trackCodes = getTracks.track.popular.list;
      // console.log(trackCodes, "trackcodes");
    }
    // Fetch data for popular tracks and update state incrementally
    fetchTrackDataIncremental(trackCodes, "popularTracks");
  };

  const fetchTrackDataIncremental = async (trackCodes, trackType) => {
    const fetchPromises = trackCodes.map(async (trackCode, index) => {
      if (trackCode.startsWith("mc-")) {
        // Fetch marketing card data from GetMktCard
        const marketingCard = GetMktCard("track", "c", index);
        return {
          type: "marketing",
          baseUrl: marketingCard.baseUrl,
          image: marketingCard.image,
          link: marketingCard.link,
        };
      }
      const trackData = await getMultiTrack([trackCode]); // Fetch data for a single track

      const track = trackData?.data?.tracks?.[0];
      if (!track) return null; // Skip if track data is invalid

      const { costPrice, sellingPrice, id } = track.SKU[0];
      const discountPercent = (
        ((costPrice - sellingPrice) / costPrice) *
        100
      ).toFixed(0);

      const artistNames = track.primaryArtists?.length
        ? track.primaryArtists.map((artist) => artist.artistName).join(", ")
        : "";

      // Process and return the track with the order field
      return {
        image: track.imageUrl || randomDefaultImage("track"),
        title:
          track.name.length > 18
            ? track.name.substring(0, 17) + "..."
            : track.name,
        description:
          artistNames.length > 30
            ? artistNames.substring(0, 29) + "..."
            : artistNames,
        buttonText: "Explore",
        buttonLink: "#",
        discountPercent: discountPercent || "50% OFF",
        costPrice: costPrice.toLocaleString("en-IN") || "",
        sellingPrice: sellingPrice.toLocaleString("en-IN") || "",
        name: track.name_slug,
        trackCode: track.trackCode,
        trackUrl: track.mp3Link || getDirectLink(track.link),
        waveJson: track.waveformLink,
        skuId: id || "",
      };
    });

    // Process tracks incrementally as they resolve
    const processedTracks = await Promise.all(fetchPromises);
    const validTracks = processedTracks.filter(Boolean); // Remove null values

    setPopularTracks(validTracks);
  };

  const trackCards = [
    {
      image: `${config.s3imgUrl}web/promotional/track/listing1.webp`,
      title: "Besharam Rang",
      // description: "Fresh tracks, fresh vibes! License the latest hits from Bollywood's finest.",
      description: "",
      buttonText: "Explore Track",
      width: "640px",
      code: 74,
      slug: "besharam-rang",
      link: "/tracks/besharam-rang/74",
    },
    {
      image: `${config.s3imgUrl}web/promotional/track/tb-2.webp`,
      title: "",
      // description: " Smash your year-end celebrations with the best music. License the perfect track for every occasion.",
      description: "",
      buttonText: "Explore Track",
      width: "332px",
      code: 94,
      slug: "ankhein-khuli",
      link: "/playlists/top-10-smash-hits/108",
    },
    {
      image: `${config.s3imgUrl}web/promotional/track/tb-3.webp`,
      title: "",
      // description: "Discover independent talent and license their unique music for your projects.",
      description: "",
      buttonText: "Explore Tracks",
      width: "332px",
      code: 533,
      slug: "judaaiyan",
      link: "/search?searchKeyword=valentine's day",
    },
  ];

  const renderMarketingCard = (card) => (
    <div className="marketing-card">
      <a href={card.link} target="_blank" rel="noopener noreferrer">
        <div
          className="marketing-card-image"
          style={{ backgroundImage: `url(${card.baseUrl}/${card.image})` }}
        ></div>
      </a>
    </div>
  );

  return (
    <div className="listing-container">
      <div className="track-listing-description outer">
        <h1>Shop by Track</h1>
        <h2>
          Discover and license the perfect bollywood, independent or trending
          track for your project.
        </h2>
      </div>
      {/* track card container */}
      {/* Map over trackCards to render each track card */}
      <div className="track-cards-container inner">
        {trackCards.map((card, index) => (
          <div
            key={index}
            style={{
              backgroundImage: `url(${card.image})`,
              minWidth: card.width,
            }}
            className="track-listingcard"
          >
            <div className="text-overlay">
              <div className="card-play-button">
                <FaPlay size={24} color="var(--button-primary)" />
              </div>
              <div className="card-description">
                <h1>{card.title}</h1>
                <h2>{card.description}</h2>
                {/* <Link
                  to={`/tracks/${encodeURIComponent(card.slug)}/${card.code}`}
                > */}
                <Link to={`${card.link}`}>
                  <CustomButton
                    text={card.buttonText}
                    backgroundColor="#fff"
                    borderRadius="50px"
                    borderColor="none"
                    width="182px"
                    textColor="var(--button-primary)"
                    fontSize="16px"
                    fontFamily="Raleway"
                    fontWeight="bold"
                    iconColor="var(--button-primary)"
                    icon={<IoIosArrowForward />}
                    justifyContent="space-between"
                  />
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* popular track cards container */}
      <div className="popular-track-cards-container outer-full">
        <div className="inner">
          <h1 className="ptctext">Popular Tracks</h1>
          <div className="popular-track-cards">
            <TrackCard
              trackCard={popularTracks}
              btnWidth="186px"
              trackType="contemporary"
            />
          </div>
        </div>
      </div>
      {/* all songs container */}
      <div style={{ marginTop: "2px" }}>
        <AllTrackCards viewall={false} />
      </div>
    </div>
  );
};

export default TrackListing;
