import React, { useRef, useEffect, useState } from "react";
import styles from "./Carousel.module.css";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

const Carousel = ({
  children,
  gap = 10,
  autoScroll = true,
  scrollInterval = 3000,
  type,
}) => {
  const containerRef = useRef(null);
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const [itemsPerView, setItemsPerView] = useState(3);

  // Duplicate children for infinite scrolling
  const items = [...children, ...children];

  // Update itemsPerView based on screen width
  const updateItemsPerView = () => {
    if (
      window.innerWidth <= 320 ||
      window.innerWidth <= 375 ||
      window.innerWidth <= 481
    ) {
      setItemsPerView(1);
    } else if (window.innerWidth <= 768) {
      setItemsPerView(2);
    } else {
      setItemsPerView(3);
    }
  };

  useEffect(() => {
    updateItemsPerView();
    window.addEventListener("resize", updateItemsPerView);
    return () => window.removeEventListener("resize", updateItemsPerView);
  }, []);

  // Check scroll position to show/hide buttons
  const checkScrollPosition = () => {
    if (!containerRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
    setShowPrev(scrollLeft > 0);
    setShowNext(scrollLeft + clientWidth < scrollWidth);
  };

  // Scroll function (ensuring full item visibility)
  const scroll = (direction) => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    const itemWidth = container.firstChild
      ? container.firstChild.offsetWidth + gap
      : 300; // Dynamic width
    const scrollAmount =
      direction === "left"
        ? -itemWidth * itemsPerView
        : itemWidth * itemsPerView;

    container.scrollBy({ left: scrollAmount, behavior: "smooth" });

    setTimeout(checkScrollPosition, 300); // Allow smooth scroll to finish
  };

  // Auto-scroll effect without continious looping
  // useEffect(() => {
  //   if (!autoScroll) return;

  //   const interval = setInterval(() => {
  //     if (showNext) {
  //       scroll("right");
  //     } else {
  //       clearInterval(interval); // Stop auto-scroll when at the end

  //       // containerRef.current.scrollTo({ left: 0, behavior: "smooth" }); // Reset to start
  //     }
  //   }, scrollInterval);

  //   return () => clearInterval(interval);
  // }, [autoScroll, scrollInterval, showNext, itemsPerView]);

  // Auto-scroll effect (continuous loop)
  useEffect(() => {
    if (!autoScroll || !containerRef.current) return;

    const container = containerRef.current;
    const itemWidth = container.firstChild
      ? container.firstChild.offsetWidth + gap
      : 300;

    const interval = setInterval(() => {
      container.scrollBy({ left: itemWidth, behavior: "smooth" });

      setTimeout(() => {
        if (container.scrollLeft >= container.scrollWidth / 2) {
          // Reset to avoid sudden jumps
          container.scrollTo({ left: 0, behavior: "smooth" });
        }
      }, 500);
    }, scrollInterval);

    return () => clearInterval(interval);
  }, [autoScroll, scrollInterval, gap]);

  // Attach scroll event listener
  useEffect(() => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    container.addEventListener("scroll", checkScrollPosition);
    checkScrollPosition(); // Initial check

    return () => container.removeEventListener("scroll", checkScrollPosition);
  }, []);

  return (
    <div className={styles.carouselBoundary}>
      <div className={styles.carouselWrapper}>
        {/* Prev Button (Hidden if at Start) */}

        {showPrev && !autoScroll && (
          <button
            className={`${styles.carouselButton} ${styles.carouselButtonPrev}`}
            onClick={() => scroll("left")}
          >
            <MdKeyboardArrowLeft size={24} color="#fff" />
          </button>
        )}

        {/* Carousel Items */}
        <div
          className={styles.carouselContainer}
          ref={containerRef}
          style={{ gap: `${gap}px` }}
        >
          {React.Children.map(children, (child, index) => (
            <div
              key={index}
              className={
                type === "brands"
                  ? styles.carouselItemBrands
                  : styles.carouselItem
              }
              style={{
                width: `calc((100% - ${
                  (itemsPerView - 1) * gap
                }px) / ${itemsPerView})`,
              }}
            >
              {child}
            </div>
          ))}
        </div>

        {/* Next Button (Hidden if at End) */}
        {showNext && !autoScroll && (
          <button
            className={`${styles.carouselButton} ${styles.carouselButtonNext}`}
            onClick={() => scroll("right")}
          >
            <MdKeyboardArrowRight size={24} color="#fff" />
          </button>
        )}
      </div>
    </div>
  );
};

export default Carousel;
