import React, { useState, useRef, useEffect, useContext } from "react";
import styles from "../styles/Login.module.css";
import CustomInput from "../components/CustomInput/CustomInput";
import { IoClose } from "react-icons/io5";
import CustomDropdown from "../components/CustomDropdown/CustomDropdown";
import CustomButton from "../components/CustomButton/CustomButton";
import config from "../utils/config";
import CustomImg from "../components/CustomImg/CustomImg";
import {
  otpSend,
  otpVerify,
  userLogin,
  emailOtp,
  emailVerify,
} from "../service/Auth";
import { ToastContext } from "../utils/ToastContext";
import { useDispatch, useSelector } from "react-redux";
import { login, logout } from "../utils/redux/authSlice";
import { updateProfile } from "../service/UserService";
import { IoMdCheckmark } from "react-icons/io";

const Login = ({ isOpen, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen); // Sync with parent initially
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verifyOtpM, setVerifyOtpM] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const inputRefs = useRef([]);
  const [mobileLogin, setMobileLogin] = useState(false);
  const [gmailLogin, setGmailLogin] = useState(false);
  const [profileVerify, setProfileVerify] = useState(false);
  const [loginMode, setLoginMode] = useState(false);
  const [profile, setProfile] = useState(false);
  const [email, setEmail] = useState(""); // Store email input
  const [emailError, setEmailError] = useState(false); // Store email validation error
  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const [name, setName] = useState(""); // State for name input for profile completion
  const [companyName, setCompanyName] = useState(""); // State for company name profile completion
  const [companyType, setCompanyType] = useState(""); // State for company type profile completion
  const [emailVerified, setEmailVerified] = useState(false); // state to manage to check whether the email for profile completion login via mobile is done or not
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    companyName: "",
    companyType: "",
  });

  const dispatch = useDispatch(); // dispatching action to the redux
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // Sync local state when parent changes `isOpen`
  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  // Sync local state when parent changes `isAuthenticated`
  useEffect(() => {
    // console.log("auth", isAuthenticated);
    // console.log(profile, "profile");
    if (isAuthenticated) {
      // console.log("auth 57", isAuthenticated);
      setMobileLogin(true); // Set login state
      setLoginMode(false); // Disable login UI after login
      setProfile(true); // Show profile completion UI
    } else {
      setMobileLogin(false); // Set login state
      setLoginMode(true); // Show login UI when not logged in
      setProfile(false); // Hide profile UI
    }
  }, [isOpen]);

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false); // Close modal locally
    resetState(); // Reset state when modal is closed
    onClose(); // Notify the parent to close the modal
  };

  // fucntion to handle the change in phone number
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and prevent any non-digit characters
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  // fucntion to handle the login via  mobile otp method
  const handleGetOtp = async (e) => {
    e.preventDefault();
    // Log or use the phone number value
    // console.log("Phone Number:", phoneNumber);
    // preparing the payload
    const payload = {
      mobile: phoneNumber,
    };

    try {
      const sendOtp = await otpSend(payload);
      // console.log(sendOtp, "send otp resp");

      if (sendOtp.message === "OTP sent successfully!") {
        showToast("OTP Send", sendOtp.message, "success"); //display toast msg
        setVerifyOtpM(true);
      } else {
        showToast("OTP Send", "Failed to send OTP", "error"); //display toast msg
        return;
      }
    } catch (error) {
      showToast("OTP Send", "Failed to send OTP", "error"); //display toast msg
      return;
    }
  };

  // function to get the otp again while login with mobile
  const handleResendGetOtp = async () => {
    // Log or use the phone number value
    // console.log("Phone Number:", phoneNumber);
    // preparing the payload
    const payload = {
      mobile: phoneNumber,
    };

    try {
      const sendOtp = await otpSend(payload);
      // console.log(sendOtp, "send otp resp");

      if (sendOtp.message === "OTP sent successfully!") {
        showToast("OTP Send", sendOtp.message, "success"); //display toast msg
        setVerifyOtpM(true);
      } else {
        showToast("OTP Send", "Failed to send OTP", "error"); //display toast msg
        return;
      }
    } catch (error) {
      showToast("OTP Send", "Failed to send OTP", "error"); //display toast msg
      return;
    }
  };

  // function to handle the otp change
  const handleOtpChange = (index, value) => {
    if (!/^\d?$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1].focus(); // Move to the next input box
    }
  };

  // function to handle the verify otp via mobile otp
  const verifyOtp = async (e) => {
    e.preventDefault();

    // getting the otp input boxes values
    // console.log(otp.join(""));
    // preparing payload for verifying the otp
    const payload = {
      mobile: phoneNumber,
      otp: otp.join(""),
    };
    try {
      const verifyOtp = await otpVerify(payload);
      // console.log(verifyOtp, "verify otp resp");
      if (verifyOtp.message === "OTP verified successfully!") {
        handleLogin();
      } else {
        showToast("OTP Verified", "Failed to Verify OTP", "error"); //display toast msg
        return;
      }
    } catch (error) {
      showToast("OTP Verified", "Failed to Verify OTP", "error"); //display toast msg
      return;
    }
  };

  // function to handle to call the login api after verification
  const handleLogin = async () => {
    // deleteCookie("skipProfile"); // just to test the case not to uncomment for debug
    // Prepare the payload
    const payload = {
      mobile: phoneNumber,
      email: email,
      method: "direct",
    };

    try {
      const loginResp = await userLogin(payload);
      // console.log(loginResp, "login resp");

      if (loginResp?.error?.code === 0) {
        const { token, userData } = loginResp.data;

        // Dispatch login with token and userData
        dispatch(
          login({
            token,
            userData,
          })
        );

        // Store userData in localStorage
        localStorage.setItem("userData", JSON.stringify(userData));

        // Check if user has skipped profile completion
        const hasSkippedProfile = getCookie("skipProfile") === "true";

        // Check if specific fields are null or empty
        const { name, email, companyType, companyName } = userData;
        const isDataIncomplete =
          !name || !email || !companyType || !companyName;

        if (isDataIncomplete && !hasSkippedProfile) {
          setMobileLogin(true); // Set login state
          setProfile(true); // Set profile UI
          setLoginMode(false);
        } else {
          setLoginMode(true);
          closeModal(); // If profile is complete or skipped, close modal
        }
        showToast("Login Successful", "Welcome!", "success");
        // setLoginMode(false);
      } else {
        showToast("Login Failed", "Failed to login. Please try again", "error");
        dispatch(logout());
      }
    } catch (error) {
      console.error(error);
      showToast("Login Failed", "Something Went Wrong!!", "error");
      dispatch(logout());
    }
  };

  // Utility to set a cookie for profile complete
  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
  };

  // Utility to get a cookie profile complete
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
      const [key, value] = cookie.split("=");
      acc[key] = value;
      return acc;
    }, {});
    return cookies[name];
  };

  // Utility to delete a cookie (optional, for clearing test cases) profile complete for later use if manunally need to do it
  // const deleteCookie = (name) => {
  //   document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
  // };

  // function to handle the skip profile
  const handleSkipProfile = () => {
    setCookie("skipProfile", "true", 30); // Set cookie to indicate profile was skipped for 1 month
    setLoginMode(false);
    closeModal(); // Close the modal
    // const storedUserData = JSON.parse(localStorage.getItem("userData")) || {};

    // setFormData((prev) => ({
    //   ...prev,
    //   name: storedUserData.name ?? "",
    //   email: storedUserData.email ?? "",
    //   mobile: storedUserData.mobile ?? "",
    //   companyName: storedUserData.companyName ?? "",
    //   companyType: storedUserData.companyType ?? "",
    // }));
    // setName((prevState) => {
    //   return {
    //     ...prevState,
    //     name: storedUserData.name,
    //   };
    // });
  };

  const handleInputChange = (e) => {
    const storedUserData = JSON.parse(localStorage.getItem("userData")) || {};
    console.log(storedUserData, "storedUserData");
    const value = storedUserData.name || e.target.value;
    setName(value);
    console.log(name, email, companyName);

    // debouncedInputChange(value); // Apply debounce to input change handling
  };

  // function to handle the complete profile
  const handleCompleteProfile = async () => {
    // create payload
    const payload = {
      name: name,
      email: email,
      companyName: companyName,
      companyType: companyType,
      mobile: phoneNumber,
    };
    // console.log(payload);

    // // Check if any value in the payload is null, undefined, or an empty string
    // const isPayloadValid = Object.values(payload).every(
    //   (value) => value !== null && value !== undefined && value !== ""
    // );

    // if (!isPayloadValid) {
    //   showToast("User Profile", "Incomplete Profile Info", "error");
    //   return;
    // } else if (emailVerified) {
    //   showToast("User Profile", "Please Verify the Email", "error");
    //   return;
    // }

    const profileUpdate = await updateProfile(payload);
    // console.log(profileUpdate);
    if (profileUpdate?.error?.code === 0) {
      showToast("User Profile", "Profile Updated Successfully", "success");
      setLoginMode(true);
      closeModal();
    } else {
      showToast("User Profile", "Error Updating Profile", "error");
    }
  };

  // email otp function
  const handleEmailOtp = async () => {
    setOtp(new Array(6).fill(""));
    if (emailError || !email) {
      showToast("Email Error", "Incorrect Email Format", "error");
      return;
    }
    setProfileVerify(true);
    if (mobileLogin) {
      // create payload
      const payload = {
        email: email,
      };
      // console.log(payload, "email to be verify");
      const getEmailOtp = await emailOtp(payload);
      // console.log(getEmailOtp, "email otp");
      if (getEmailOtp?.error?.code === 0) {
        showToast("Email OTP", "Otp Sent successfully", "success");
      } else {
        showToast("Email Error", "Fail to send otp", "error");
      }
    }
  };

  // email verify function
  const handleEmailVerify = async () => {
    // create payload
    const payload = {
      email: email,
      otp: otp.join(""),
    };

    const verifyemail = await emailVerify(payload);
    // console.log(verifyemail, "email verify");
    if (verifyemail.error.code === 0) {
      showToast("Email Verify", "Otp Verified successfully", "success");
      setEmailVerified(true);
      setProfileVerify(false);
      setOtp(new Array(6).fill(""));
    } else {
      showToast("Email Error", "Fail to verify otp", "error");
    }
  };

  // resend otp fucntion while completing profile
  const handleResendOtp = async () => {
    setOtp(new Array(6).fill(""));
    // resend otp will based on mobilelogin or gmaillogin condition
    if (mobileLogin) {
      // resend otp for email
      // create payload
      const payload = {
        email: email,
      };
      const resendOtp = await emailOtp(payload);
      if (resendOtp?.error?.code === 0) {
        showToast("Email OTP", "Otp Sent successfully", "success");
      } else {
        showToast("Email Error", "Fail to send otp", "error");
      }
    }
  };

  // fucntion to handle the backspace for otp input boxes
  const handleBackspace = (index) => {
    if (index > 0 && !otp[index]) {
      inputRefs.current[index - 1].focus(); // Move to the previous input box
    }
  };

  // fucntion to reset the all login state to default
  const resetState = () => {
    setPhoneNumber("");
    setVerifyOtpM(false);
    setOtp(new Array(6).fill(""));
    setMobileLogin(false);
    setGmailLogin(false);
    setProfileVerify(false);
    setLoginMode(false);
    setProfile(false);
    setEmail(""); // Clear email state
    setEmailError(""); // Clear email error
  };

  // email input handler regex check
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Validate email with regex
    if (!emailRegex.test(value)) {
      setEmailError(true);
    } else {
      setEmailError(false); // Clear error if email is valid
    }
  };

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // dropdown values
  const options = [
    { value: "Agency", label: "Agency" },
    { value: "Brand", label: "Brand" },
    { value: "Content Studio", label: "Content Studio" },
    { value: "Independent Filmmaker", label: "Independent Filmmaker" },
    {
      value: "Individual Creator / Influencer",
      label: "Individual Creator / Influencer",
    },
    { value: "OTT Platform", label: "OTT Platform" },
    { value: "Production House", label: "Production House" },
    { value: "Radio Station", label: "Radio Station" },
    { value: "TV Channel", label: "TV Channel" },
    { value: "Other", label: "Other" },
  ];

  if (!isModalOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div
        className={styles.modalContainer}
        style={{
          // backgroundImage: `url(
          //       ${config.s3imgUrl}web/onboarding/login.webp
          //     )`,
          backgroundImage: `url(
            ${config.s3imgUrl}web/onboarding/login-n2.png
          )`,
        }}
      >
        {/* login with otp and gmail section */}
        {loginMode && (
          <div className={styles.innerContent}>
            {/* <div onClick={closeModal} className={styles.closeIconMain}>
              <IoClose
                cursor="pointer"
                onClick={() => !isOpen}
                size={24}
                color="#000"
              />
            </div> */}
            {verifyOtpM === false ? (
              <form className={styles.login} onSubmit={handleGetOtp}>
                {/* <div className={styles.login}> */}
                <p className={styles.loginText}>Log In</p>
                <div className={styles.phoneInput}>
                  <select className={styles.countryCode}>
                    <option value="+91">+91</option>
                  </select>
                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    className={styles.phoneNumber}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    maxLength={10}
                  />
                </div>
                <button
                  className={styles.getOtpButton}
                  // onClick={handleGetOtp}
                >
                  Get OTP
                </button>
                {/* <div className={styles.separator}>
                  <span>OR</span>
                </div> */}
                {/* <button
                  // onClick={() => {
                  //   setProfile(true);
                  //   setLoginMode(false);
                  //   setGmailLogin(true);
                  // }}
                  className={styles.googleButton}
                >
                  <img
                    src="https://www.gstatic.com/images/branding/product/1x/gsa_512dp.png"
                    alt="Google"
                    className={styles.googleIcon}
                  />
                  Sign In With Google
                </button> */}
                {/* <CustomImg
                  src={`${config.s3imgUrl}web/onboarding/google-light-si-sq.png`}
                  id="google-sign-in"
                  alt="sign-in-with-google"
                  imgClass={styles.googleSignIn}
                  type="genre"
                /> */}
                {/* </div> */}
              </form>
            ) : (
              // login with otp verify section
              <form onSubmit={verifyOtp} className={styles.login}>
                {/* <div className={styles.login}> */}
                <p className={styles.loginText}>Verify OTP</p>
                <div className={styles.checkboxContainer}>
                  <div className={styles.otpNumber}>
                    <p className={styles.otp}>OTP sent to +91-{phoneNumber}</p>
                    <p
                      onClick={() => {
                        setVerifyOtpM(false);
                        setOtp(new Array(6).fill("")); // Clear the OTP inputs
                      }}
                      className={styles.edit}
                    >
                      Edit
                    </p>
                  </div>
                  <div className={styles.otpContainer}>
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="text"
                        className={`${styles.otpInput} ${
                          digit ? styles.otpFilled : ""
                        }`}
                        value={digit}
                        maxLength={1}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Backspace") handleBackspace(index);
                        }}
                        autoFocus={index === 0}
                      />
                    ))}
                  </div>
                  <p className={styles.resend}>
                    Didn't get a code? {""}
                    <span
                      onClick={() => {
                        setOtp(new Array(6).fill("")); // Clear the OTP inputs
                        // handleGetOtp();
                        handleResendGetOtp();
                      }}
                      className={styles.resendSpan}
                    >
                      {""}Click here to resend
                    </span>
                  </p>
                  <button
                    // onClick={() => {
                    //   // call the verfiy otp func here
                    //   verifyOtp();
                    // }}
                    className={styles.getOtpButton}
                  >
                    Verify
                  </button>
                </div>
                {/* </div> */}
              </form>
            )}
            <div className={styles.privacy}>
              By continuing, you agree to Hoopr Smash{" "}
              <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              .
            </div>
          </div>
        )}
        {profile && (
          // profile info section
          <div className={styles.profileContent}>
            <div onClick={handleSkipProfile} className={styles.closeIcon}>
              <IoClose
                cursor="pointer"
                onClick={() => !isOpen}
                size={24}
                color="#000"
              />
            </div>
            <p
              style={{ margin: profileVerify ? "0px" : "" }}
              className={styles.profileText}
            >
              Enhance Experience by Completing Profile
            </p>
            <div className={styles.inputContainer}>
              <CustomInput
                fontSize="16px"
                marginTop="5px"
                paddingLeft="22px"
                labelOpcaity="30%"
                fontSizeLabel="14px"
                border="1px solid #98999C"
                labelTop="15px"
                fontLabelColor="#98999C"
                height="50px"
                placeholder="Name"
                borderRadius="8px"
                value={name}
                // onChange={(e) => setName(e.target.value)}
                onChange={handleInputChange}
              />
              {/* profile mobile and email verify based on login mode */}
              <div className={styles.verfiyProfile}>
                {gmailLogin && (
                  <div className={styles.phoneInputProfile}>
                    <select className={styles.countryCode}>
                      <option value="+91">+91</option>
                    </select>
                    <input
                      type="text"
                      placeholder="Enter Phone Number"
                      className={styles.phoneNumber}
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      maxLength={10}
                    />
                  </div>
                )}
                {mobileLogin && (
                  <CustomInput
                    fontSize="16px"
                    marginTop="5px"
                    paddingLeft="22px"
                    labelOpcaity="30%"
                    fontSizeLabel="14px"
                    border="1px solid #98999C"
                    labelTop="15px"
                    fontLabelColor="#98999C"
                    height="50px"
                    width="288px"
                    placeholder="Email Address"
                    borderRadius="8px"
                    containerWidth="auto"
                    onChange={handleEmailChange}
                    value={email}
                  />
                )}
                {emailVerified ? (
                  <div className={styles.emailVerified}>
                    <IoMdCheckmark size={22} color="#fff" />
                  </div>
                ) : (
                  <>
                    {profileVerify ? (
                      <button
                        onClick={handleEmailVerify}
                        className={styles.profileVerifyBtn}
                      >
                        Verify
                      </button>
                    ) : (
                      <button
                        onClick={handleEmailOtp}
                        className={styles.profileVerifyBtn}
                      >
                        Get Otp
                      </button>
                    )}
                  </>
                )}
              </div>
              {profileVerify && (
                <div>
                  <div className={styles.otpContainerProfile}>
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="text"
                        className={`${styles.otpInputProfile} ${
                          digit ? styles.otpFilled : ""
                        }`}
                        value={digit}
                        maxLength={1}
                        onChange={(e) => handleOtpChange(index, e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Backspace") handleBackspace(index);
                        }}
                        autoFocus={index === 0}
                      />
                    ))}
                  </div>
                  <p className={styles.resend}>
                    Didn't get a code? {""}
                    <span
                      style={{ cursor: "pointer" }}
                      className={styles.resendSpan}
                      onClick={handleResendOtp}
                    >
                      {""}Click here to resend
                    </span>
                  </p>
                </div>
              )}
              <CustomInput
                fontSize="16px"
                marginTop="5px"
                paddingLeft="22px"
                labelOpcaity="30%"
                fontSizeLabel="14px"
                border="1px solid #98999C"
                labelTop="15px"
                fontLabelColor="#98999C"
                height="50px"
                placeholder="Company Name"
                borderRadius="8px"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
              <CustomDropdown
                options={options}
                placeholder="Type of company"
                value={options.find((option) => option.value === companyType)} // Ensures the dropdown shows the correct selected value
                onChange={(selectedOption) =>
                  setCompanyType(selectedOption ? selectedOption.value : "")
                }
              />
            </div>
            <div className={styles.buttonContainercp}>
              <CustomButton
                text="Complete Profile"
                backgroundColor="var(--button-primary)"
                borderRadius="50px"
                width="100%"
                height="48px"
                textColor="#fff"
                fontSize="16px"
                fontFamily="Raleway"
                fontWeight="550"
                onClick={handleCompleteProfile}
              />
            </div>
            <span onClick={handleSkipProfile} className={styles.skip}>
              Skip for Now
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
