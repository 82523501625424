// src/store/authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const savedUserData = localStorage.getItem("userData");

// Helper function to get a cookie value
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
};

// Helper function to set a cookie

const setCookie = (name, value, days) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  const secureFlag = window.location.protocol === "https:" ? "Secure" : "";
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/;${secureFlag}`;
};

// Helper function to remove a cookie
const deleteCookie = (name) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: !!getCookie("authToken"), // Check for cookie
    userData: savedUserData ? JSON.parse(savedUserData) : null, // Retrieve from localStorage
    token: getCookie("authToken"), // Store token
  },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.userData = action.payload.userData; // Save user data
      state.token = action.payload.token; // Save token
      setCookie("authToken", action.payload.token, 1); // Save token to cookies
      localStorage.setItem("userData", JSON.stringify(action.payload.userData)); // Store in localStorage
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.userData = null;
      state.token = null;
      deleteCookie("authToken");
      localStorage.removeItem("userData"); // Clear localStorage on logout
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
