import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import CustomArrow from "./CustomArrow";
import "./CustomButton.css";

const CustomButton = ({
  text,
  icon,
  backgroundColor,
  iconColor,
  iconSize,
  borderColor,
  textColor,
  borderRadius,
  onClick,
  isIconOnly,
  hoverEffect,
  height,
  fontSize,
  fontWeight,
  buyArrow,
  justifyContent,
  width,
  fontFamily,
  type,
}) => {
  // State to track window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Effect to update width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Check if it's mobile or tablet
  const isMobile = windowWidth <= 425;
  const isTablet = windowWidth <= 768;
  return (
    <button
      className={`custom-button ${hoverEffect ? "hover-effect" : ""} ${
        isIconOnly ? "icon-only" : ""
      }`}
      onClick={onClick}
      style={{
        backgroundColor: backgroundColor || "transparent",
        color: textColor || (backgroundColor ? "#fff" : "#000"),
        border: borderColor || (borderColor ? "none" : "#000"),
        borderRadius: borderRadius || "4px",
        height: height || "40px", // Fixed height for all buttons
        // width: width || "auto", // auto width default
        fontSize: isMobile ? "13px" : fontSize || "14px",
        // height: isMobile ? "36px" : isTablet ? "36px" : height || "40px",
        width:
          type === "cart"
            ? isMobile
              ? "30px"
              : isTablet
              ? "37px"
              : width || "auto"
            : width || "auto",
        fontWeight: fontWeight || "normal",
        fontFamily: fontFamily || "Inter",
        justifyContent: justifyContent || "center",
        position: "relative", // Ensure the arrow stays within the button added for custom arrrow component
        overflow: "hidden", // Prevent overflow of arrow icons added for custom arrrow component
      }}
    >
      {!isIconOnly && text}
      {buyArrow ? (
        <div
          className="triple-arrow-icon"
          style={{ transform: isMobile ? "scale(0.85)" : "scale(1)" }}
        >
          <CustomArrow />
        </div>
      ) : (
        icon && (
          <IconContext.Provider
            value={{
              // size: iconSize || "20px",
              size: isMobile ? "16px" : iconSize || "20px",
              style: {
                marginLeft: text ? "8px" : "0",
                color: iconColor ? iconColor : "#000",
              },
            }}
          >
            {icon}
          </IconContext.Provider>
        )
      )}
    </button>
  );
};

export default CustomButton;
