import React, { useState, useRef, useEffect } from "react";
import styles from "../styles/HowtoUse.module.css";
import config from "../utils/config";
import { FaPlay, FaPause } from "react-icons/fa";

const HowtoUse = () => {
  const playerRefs = useRef([]);
  const [players, setPlayers] = useState([]);

  const htuPills = [
    "About Hoopr Smash",
    "Navigating Hoopr Smash",
    "Music Catalog and Availability",
    "Call For Artists",
    "Purchase and Checkout Flow",
    "Decoding Clearance Rejections",
    "Music Usage & Clearance",
  ];

  const videoData = [
    {
      title: "About Hoopr Smash",
      description:
        "An introduction to Hoopr Smash, its features, and how it simplifies music licensing.",
      videoId: "kQNmhj0IcfM",
      timestamps: [
        { title: "Introduction", time: 0 },
        { title: "What is Hoopr Smash?", time: 24 },
        { title: "What sets Hoopr Smash apart?", time: 49 },
        { title: "How to use Hoopr Smash?", time: 98 },
        { title: "Why choose Hoopr Smash vs traditional licensing?", time: 184 },
        { title: "Can I request a song that is not a part of the catalog?", time: 221 },
        { title: "Do I need to pay extra royalties for the tracks I have licensed from Hoopr Smash?", time: 255 },
        { title: "What happens after my license expires?", time: 273 },
        { title: "Need more support?", time: 306 },
      ],
    },
    {
      title: "Navigating Hoopr Smash",
      description:
        "A walkthrough of the platform, from account setup to discovering and purchasing music.",
      videoId: "kQNmhj0IcfM",
      timestamps: [
        { title: "Introduction", time: 0 },
        { title: "How do I create my account?", time: 24 },
        { title: "How do I Update my profile?", time: 24 },
        { title: "What kind of assets are available for licensing on the platform?", time: 50 },
        { title: "How do I search and discover the right music for my brand?", time: 98 },
        { title: "Why do tracks on Hoopr Smash play only for 30 seconds at a time?", time: 167 },
        { title: "What is the difference between a track, pack and playlist?", time: 221 },
        { title: "How do I save tracks, packs and playlists that I like?", time: 255 },
        { title: "How do I access tracks, packs and playlists that I have liked?", time: 272 },
        { title: "What are 'Collections'?", time: 305 },
        { title: "How do I save tracks, packs and playlists to 'Collections'?", time: 305 },
        { title: "How do I access tracks, packs, and playlists I have added to 'Collections'?", time: 305 },
        { title: "Can I edit and modify the collections I have created under 'Collections'?", time: 305 },
        { title: "Can I share tracks, packs and playlists with other people?", time: 305 },
        { title: "How do I purchase a track?", time: 305 },
        { title: "How do I purchase a pack?", time: 305 },
        { title: "How can I track my purchases and licenses?", time: 305 },
        { title: "How can I use a track for content longer than 60 seconds, such as YouTube videos, short films, OTT platforms, or other long-form formats?", time: 305 },
        { title: "How do I request for a track that is currently unavailable in the listed catalogue?", time: 305 },
        { title: "How do I reach out to the Hoopr Smash Support team?", time: 305 },
        { title: "Need more support?", time: 305 },
      ],
    },{
      title: "Music Catalog and Availability",
      description:
        "Insights into the types of music available on Hoopr Smash and licensing details.",
      videoId: "kQNmhj0IcfM",
      timestamps: [
        { title: "Introduction", time: 0 },
        { title: "What kind of music is available on Hoopr Smash?", time: 24 },
        { title: "Is Hoopr Smash authorised to license this music on behalf of labels and artists?", time: 50 },
        { title: "How often is the music catalog updated?", time: 98 },
        { title: "Can individual creators license Bollywood tracks?", time: 167 },
        { title: "Do I need a subscription for streaming?", time: 221 },
        { title: "Do I need to pay extra royalties for the tracks I have licensed from Hoopr Smash?", time: 255 },
        { title: "What happens after my license expires?", time: 272 },
        { title: "Need more support?", time: 305 },
      ],
    },{
      title: "Call For Artists",
      description:
        "A guide for independent artists on how to publish, monetize, and onboard their music on Hoopr Smash.",
      videoId: "kQNmhj0IcfM",
      timestamps: [
        { title: "Introduction", time: 0 },
        { title: "Can independent artists publish their music on Hoopr Smash?", time: 24 },
        { title: "How can independent artists publish their music on Hoopr Smash?", time: 50 },
        { title: "How can artists earn through Hoopr Smash?", time: 98 },
        { title: "As an artist, how can I upload my songs onto Hoopr Smash?", time: 167 },
        { title: "What kind of music can I add to Hoopr Smash as an artist?", time: 221 },
        { title: "How long does it take for the Hoopr Smash team to review and onboard an artist?", time: 255 },
        { title: "Need more support?", time: 305 },
      ],
    },{
      title: "Purchase and Checkout Flow",
      description:
        "A step-by-step breakdown of purchasing tracks, payment methods, and tracking licenses.",
      videoId: "kQNmhj0IcfM",
      timestamps: [
        { title: "Introduction", time: 0 },
        { title: "How do I purchase a track?", time: 24 },
        { title: "How do I purchase a pack?", time: 50 },
        { title: "How can I track my purchases and licenses?", time: 98 },
        { title: "Are taxes included in the price?", time: 167 },
        { title: "What payment methods do you accept while purchasing a track or pack?", time: 221 },
        { title: "What happens after I make my purchase?", time: 255 },
        { title: "What if I don’t receive my purchase confirmation email?", time: 272 },
        { title: "What happens if I do not utilise all permissible usage links before the expiry of the license validity? Can I get an extension on the license or a partial refund?", time: 272 },
        { title: "Need more support?", time: 305 },
      ],
    },{
      title: "Decoding Clearance Rejections",
      description:
        "Understanding clearance rejections, common issues, and how to avoid them.",
      videoId: "kQNmhj0IcfM",
      timestamps: [
        { title: "Introduction", time: 0 },
        { title: "What is a clearance rejection?", time: 24 },
        { title: "What are the guidelines that I need to follow so that my content clearance does not get rejected?", time: 50 },
        { title: "Why would my links be rejected even after following the due process and adding the links for verification?", time: 98 },
        { title: "Why have I received claims despite following all the guidelines correctly?", time: 167 },
        { title: "Will I be issued a refund if my content clearance is rejected?", time: 221 },
        { title: "Need more support?", time: 305 },
      ],
    },{
      title: "Music Usage & Clearance",
      description:
        "Guidelines on licensing, usage rights, platform compliance, and content clearance.",
      videoId: "kQNmhj0IcfM",
      timestamps: [
        { title: "Introduction", time: 0 },
        { title: "What type of licenses does Hoopr Smash provide?", time: 24 },
        { title: "Where can I use music from Hoopr Smash?", time: 50 },
        { title: "In the context of Hoopr Smash what does short-form content or short form videos mean?", time: 98 },
        { title: "How can I use a track in a short-form video that I want to create?", time: 167 },
        { title: "Can I use music from Hoopr Smash in long-format videos, ads, and broadcasts?", time: 221 },
        { title: "How many short-form videos can I create with a single track?", time: 255 },
        { title: "How many platforms can I post my content on?", time: 272 },
        { title: "What is the validity of the music I license from Hoopr Smash?", time: 272 },
        { title: "Can I create a remix or modify a track I license on Hoopr Smash?", time: 272 },
        { title: "What laws and regulations do I need to comply with while using music from Hoopr Smash?", time: 272 },
        { title: "Can I use two licensed tracks in the same piece of content?", time: 272 },
        { title: "Will I get a notification for any unused licenses?", time: 272 },
        { title: "Need more support?", time: 305 },
      ],
    },
  ];

  // Load YouTube API
  useEffect(() => {
    // Load YouTube API only if not already loaded
    if (!window.YT) {
      const script = document.createElement("script");
      script.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(script);

      // Ensure we initialize only when API is ready
      window.onYouTubeIframeAPIReady = () => {
        initializePlayers();
      };
    } else {
      initializePlayers();
    }
  }, []);

  const initializePlayers = () => {
    const newPlayers = videoData.map((video, index) => {
      if (playerRefs.current[index]) {
        return new window.YT.Player(playerRefs.current[index], {
          videoId: video.videoId,
          playerVars: {
            autoplay: 0,
            controls: 1,
            mute: 1,
          },
          //   events: {
          //     onReady: (event) => {
          //       console.log(`Player ${index} ready`);
          //     },
          //   },
        });
      }
      return null;
    });

    setPlayers(newPlayers);
  };

  // Handle timestamp click
  const handleTimestampClick = (index, time) => {
    if (players[index]) {
      players[index].seekTo(time, true);
      players[index].playVideo();
    }
  };

  return (
    <div className={`${styles.container} ${styles.outer}`}>
      <div className={styles.inner}>
        <div className={styles.htuContent}>
          <p className={styles.htuHeading}>How To Use Hoopr Smash</p>
          {/* <p className={styles.htuSubheading}>
            Welcome to the Hoopr Smash How-To Page, your go-to resource for
            navigating and mastering the platform. Below, you'll find helpful
            videos grouped by sections to guide you through every step of your
            journey with us.
          </p> */}
          <div class={styles.pillsContainer}>
            <span className={styles.subHeadingSpan}>Jump to Section</span>
            <div className={styles.htuSection}>
              {/* {htuPills.map((section, index) => (
                <p
                  key={index}
                  onClick={() => handleScrollToSection(section)}
                  className={styles.sectionName}
                  style={{ cursor: "pointer" }}
                >
                  {section}
                </p>
              ))} */}
              {htuPills.map((section, index) => (
                <a
                  key={index}
                  href={`#${section.replace(/\s+/g, "-")}`} // Convert spaces to dashes
                  className={styles.sectionName}
                >
                  {section}
                </a>
              ))}
              </div>
          </div>
        </div>
        {videoData.map((video, index) => (
          <div
            key={index}
            id={video.title.replace(/\s+/g, "-")}
            className={styles.htuSectionInfo}
          >
            <div>
              <p className={styles.sectionheader}>{video.title}</p>
              <p className={styles.sectionSubHeader}>{video.description}</p>
            </div>
            <div className={styles.sectionContent}>
              <div className={styles.sectionVideo}>
                <div
                  ref={(el) => (playerRefs.current[index] = el)}
                  className={styles.iframeContainer}
                ></div>
              </div>
              <div className={styles.videoTimeStamp}>
                {/* <p className={styles.contenttext}>Content</p> */}
                {video.timestamps.map((item, tsIndex) => (
                  <div
                    key={tsIndex}
                    className={styles.timestamp}
                    onClick={() => handleTimestampClick(index, item.time)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className={styles.tsInfo}>
                      <span>{tsIndex + 1}.</span>
                      <p>{item.title}</p>
                    </div>
                    {/* <FaPlay color="#313338" size={18} /> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowtoUse;
