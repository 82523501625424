import React, { useState, useContext } from "react";
import "./TrackCard.css";
import { useGlobalPlayer } from "../../../utils/GlobalPlayerContext";
import { RiShoppingCart2Line } from "react-icons/ri";
import CustomButton from "../../CustomButton/CustomButton";
import { FaPlay, FaPause } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { addToCart } from "../../../utils/redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContext } from "../../../utils/ToastContext";
import randomDefaultImage from "../../../utils/RandomDefaultImage";
import config from "../../../utils/config";
import { putCart } from "../../../service/TransactionService";
import { MktCards } from "../../../utils/MktCards";
import GetMktCard from "../../../utils/GetMktCard";
import RequestLicense from "../../../screens/RequestLicense";

const TrackCard = ({
  trackCard,
  trackType,
  btnWidth,
  btnHeight,
  btnSize,
  cartBgColor,
  cartIgColor,
  borderColor,
  showPrice = false,
}) => {
  const [activeTrackCode, setActiveTrackCode] = useState(null); // Track currently playing
  const { isPlaying, setIsPlaying, showPlayer } = useGlobalPlayer();
  const dispatch = useDispatch(); // Hook to dispatch actions
  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const items = useSelector((state) => state.cart.items); // Redux selector
  const [isRequestOpen, setIsRequestOpen] = useState(false); // toggle cart modal
  const [licenseData, setIsLicenseData] = useState(null); // state to manage the request quote data for request license modal

  // Utility to fetch item quantity from Redux state
  const getItemQuantity = (trackCode) =>
    items.find((item) => item.code === trackCode)?.quantity || 0;

  // function to get the details of the track trigger
  const handleTrackClick = (track) => {
    // console.log("Track Details:", track);
    const trackInfo = {
      trackImg: track.image,
      trackName: track.title,
      trackArtistName: track.description,
      trackDuration: "03:25",
      trackCode: track.trackCode,
      trackUrl: track.trackUrl,
      trackJson: track.waveJson,
    };
    if (isPlaying === true) {
      //   console.log("254", isPlaying);
      showPlayer(trackInfo);
      setIsPlaying(isPlaying);
    }
    showPlayer(trackInfo);

    // Toggle play/pause state
    // console.log(isPlaying, "257");

    setActiveTrackCode(track.trackCode);
  };

  const handleCartClick = async (track) => {
    // console.log(track, "track info for cart");
    const cartInfo = {
      cardType: "track", // card type to display
      preText: track.title, // track name
      secText: track.description, // artist name
      code: track.trackCode, // track code
      sp: track.sellingPrice, // selling price
      cp: track.costPrice, // cost price
      cardImg: track.image, // card img based on track
      trackJson: track.waveJson,
      trackUrl: track.trackUrl,
      skuID: track.skuId,
    };
    // console.log(cartInfo, "cartInfo");

    // Get the current quantity for the track
    const currentQuantity = getItemQuantity(track.trackCode);

    const updatedQuantity = currentQuantity + 1; // Increment quantity
    // console.log("Updated Quantity:", updatedQuantity);

    // preparign the payload for api call
    const payload = {
      items: [
        { skuId: track.skuId || "", qty: updatedQuantity, cartType: "C" },
      ],
    };

    const addCart = await putCart(payload);
    // console.log(addCart, "cart add");
    if (addCart?.error?.code === 0) {
      // Dispatch action to add the trak in the redux
      dispatch(addToCart(cartInfo));
      showToast("Added to cart", "Your perfect soundtrack awaits", "success"); //display toast msg
    } else {
      showToast("Added to cart", "Failed to update the cart", "error"); //display toast msg
    }
  };

  // const handleButtonClick = (track) => {
  //   // console.log("track.buttonText:", track.buttonText); // Log to debug the button text

  //   if (track.buttonText === "Request Quote") {
  //     // console.log("its request quote");
  //     // Execute the callback function when the button text is "alpha"
  //     onRequestClick(track);
  //   }
  // }; // this is function was added for for you page if page is not beign sued then remove the function

  // Track index for marketing cards (to display sequentially)
  let marketingCardCounter = 0; // Initialize counter
  let marketingBannerCounter = 0; // Counter for marketing banners

  // Utility function to render a marketing card
  const renderMarketingCard = (card) => (
    <div className="marketing-card">
      <a href={card.link}>
        <div
          className="marketing-card-image"
          style={{ backgroundImage: `url(${card.image})` }}
        ></div>
      </a>
    </div>
  );

  const renderMarketingBanner = (banner) => (
    <div
      className="marketing-banner"
      style={{ backgroundImage: `url(${banner.image})` }}
    ></div>
  );

  // function to handle the request quote of the track
  const handleRequestQuote = (track) => {
    const licenseData = {
      title: track.title,
      subTitle: track.description,
      img: config.s3imgUrl + "web/tracks/" + track.trackCode + ".webp",
      trackCode: track.trackCode,
    };
    setIsLicenseData(licenseData);
    // console.log(licenseData, "license data");
    setIsRequestOpen(true);
  };

  return (
    <div className="allsong-track-card-container" data-type={trackType}>
      {trackCard.map((track, index) => {
        // Determine what to display using MktCards
        const cardType = MktCards(index, 5, 3, 99); // Adjust parameters as needed

        if (cardType === "c") {
          const marketingCard = GetMktCard(
            "track",
            cardType,
            marketingCardCounter
          );

          marketingCardCounter++; // Increment counter

          if (marketingCardCounter >= marketingCard.max) {
            marketingCardCounter = 0; // Reset when max is reached
          }

          return (
            <React.Fragment key={index}>
              {renderMarketingCard(marketingCard)}
            </React.Fragment>
          );
        }

        // if (cardType === "b") {
        //   const marketingBanner = GetMktCard(
        //     "track",
        //     cardType,
        //     marketingBannerCounter
        //   );
        //   marketingBannerCounter++;

        //   if (marketingBannerCounter >= marketingBanner.max) {
        //     marketingBannerCounter = 0; // Reset when maxCards is reached
        //   }

        //   return (
        //     <React.Fragment key={index}>
        //       {renderMarketingBanner(marketingBanner)}
        //     </React.Fragment>
        //   );
        // }

        // Default: Render track card
        return (
          <React.Fragment key={index}>
            {/* Render track card */}
            <div className="allsong-track-card">
              <div
                className="allsong-track-card-image"
                style={{
                  backgroundImage: `url(${
                    config.s3imgUrl +
                      "web/tracks/" +
                      track.trackCode +
                      ".webp" || randomDefaultImage("track")
                  })`,
                }}
                onClick={() => {
                  handleTrackClick(track);
                  setIsPlaying(!isPlaying);
                }}
              >
                <div className="track-play-btn-container">
                  {activeTrackCode === track.trackCode && isPlaying ? (
                    <FaPause color="var(--button-primary)" size={24} />
                  ) : (
                    <FaPlay color="var(--button-primary)" size={24} />
                  )}
                </div>
              </div>
              <div className="allsong-track-card-text">
                <div className="allsong-track-title">
                  <Link
                    to={`/tracks/${encodeURIComponent(track.name)}/${
                      track.trackCode
                    }`}
                  >
                    <h1>
                      {track.title.length > 17
                        ? track.title.substring(0, 17) + "..."
                        : track.title}
                    </h1>
                  </Link>
                  <h2>
                    {track.description.length > 29
                      ? track.description.substring(0, 29) + "..."
                      : track.description}
                  </h2>
                </div>
                {/* Price section with conditional rendering */}
                {showPrice === false && (
                  <>
                    {!track.sellingPrice && !track.costPrice ? null : (
                      <div className="allsong-track-price-container">
                        {track.sellingPrice === track.costPrice ? (
                          <>
                            <div className="allsong-track-discount-price">
                              <div className="allsong-track-discount-amount">
                                <span>₹</span>
                                <p>{track.sellingPrice}</p>
                              </div>
                            </div>
                            {/* adding black div to maintain the css structure for temproray */}
                            <div style={{ height: 16 }}></div>
                          </>
                        ) : (
                          <>
                            <div className="allsong-track-discount-price">
                              <div className="allsong-track-discount-amount">
                                <span>₹</span>
                                <p>{track.sellingPrice}</p>
                              </div>
                              <div className="allsong-track-discount-percentage">
                                {track.discountPercent}% Off
                              </div>
                            </div>
                            <div className="allsong-track-cost-price">
                              <span>₹{track.costPrice}</span>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    <div className="allsong-explore-track-button">
                      {!track.sellingPrice && !track.costPrice ? (
                        <div>
                          <CustomButton
                            text="Request Quote"
                            backgroundColor="#fff"
                            borderRadius="50px"
                            borderColor="1px solid #CCCCCC"
                            width="100%"
                            height={btnHeight || "37px"}
                            textColor="#272727"
                            iconColor="#272727"
                            fontSize="16px"
                            fontFamily="Inter"
                            fontWeight="550"
                            icon={<IoIosArrowForward />}
                            justifyContent="space-between"
                            onClick={() => handleRequestQuote(track)}
                          />
                        </div>
                      ) : (
                        <>
                          <div>
                            <Link
                              to={`/tracks/${encodeURIComponent(track.name)}/${
                                track.trackCode
                              }`}
                            >
                              <CustomButton
                                text={track.buttonText}
                                backgroundColor="#fff"
                                borderRadius="50px"
                                borderColor="1px solid #CCCCCC"
                                // width="148px"
                                width={btnWidth || "194px"}
                                // height="29px"
                                height={btnHeight || "37px"}
                                textColor="#272727"
                                iconColor="#272727"
                                // fontSize="13px"
                                fontSize="16px"
                                fontFamily="Inter"
                                fontWeight="550"
                                icon={<IoIosArrowForward />}
                                justifyContent="space-between"
                              />
                            </Link>
                          </div>
                          <div>
                            <CustomButton
                              icon={<RiShoppingCart2Line />}
                              isIconOnly={true}
                              backgroundColor={cartBgColor || "#272727"}
                              iconColor={cartIgColor || "#fff"}
                              borderRadius="50px"
                              height={btnSize || "37px"}
                              width={btnSize || "37px"}
                              onClick={() => {
                                // setIsCartOpen(true);
                                handleCartClick(track);
                              }}
                              borderColor={borderColor || "rgb(0, 0, 0)"}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </React.Fragment>
        );
      })}
      {/* request quote screen */}
      <RequestLicense
        isOpen={isRequestOpen}
        onClose={() => setIsRequestOpen(false)}
        licenseData={licenseData}
      />
    </div>
  );
};

export default TrackCard;
