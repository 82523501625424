import React, { useState, useEffect } from "react";
import styles from "../../styles/MyAccountCss/Profile.module.css";
import { FiPlus, FiEdit } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomButton from "../../components/CustomButton/CustomButton";
import Select from "react-select";
import { getUserProfile } from "../../service/UserService";
import Login from "../Login";

const MyProfile = () => {
  const [billingInfo, setBillingInfo] = useState([]); // user billing info data
  const [defaultBillingChecked, setDefaultBillingChecked] = useState(false); // state for checkbox of default billing info
  const [showModal, setShowModal] = useState(false); // Modal visibility state for displaying the user billing info
  const [phoneNumber, setPhoneNumber] = useState(""); // phone number
  const [editIndex, setEditIndex] = useState(null); // Track index of the billing info being edited
  const [userProfile, setUserProfile] = useState(null); // state to amange the user prfile info
  const [isIncompleteProfile, setIsIncompleteProfile] = useState(false); // state to manage the profile completion status
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); // toggle login modal

  // Input states for user info billing
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [pincode, setPincode] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");

  const [selectedOption, setSelectedOption] = useState(""); // state to manage the selected user billing details

  // get the user profile
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await getUserProfile();
        // console.log(response, "user profile");

        if (response.error.code === 0) {
          // Ensure the data exists and is structured as expected
          const profile = response.data.profile?.[0] || null;
          setUserProfile(profile);
          // Check if profile is incomplete
          if (
            !profile?.name ||
            !profile?.email ||
            !profile?.mobile ||
            !profile?.companyName ||
            !profile?.companyType
          ) {
            setIsIncompleteProfile(true);
          } else {
            setIsIncompleteProfile(false);
          }
        } else {
          setUserProfile(null);
          setIsIncompleteProfile(true); // No profile means it's incomplete
        }
      } catch (error) {
        // console.error("Error fetching user profile:", error);
        setUserProfile(null);
        setIsIncompleteProfile(true); // No profile means it's incomplete
      }
    };
    fetchUserProfile();
  }, []);

  // handle check box for default billing info
  const handleBillCheckboxChange = () => {
    setDefaultBillingChecked(!defaultBillingChecked);
  };

  // to display the user bill info add modal
  const toggleModal = () => {
    if (!showModal) {
      // Clear form fields only when opening the modal for a new entry
      setCompanyName("");
      setPhoneNumber("");
      setEmail("");
      setAddressLine1("");
      setAddressLine2("");
      setCity(null);
      setState(null);
      setPincode("");
      setGstNumber("");
      setPanNumber("");
      setDefaultBillingChecked(false);
    }
    setShowModal(!showModal);
  };

  // handle phone number input
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and prevent any non-digit characters
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  // function to save user bill info details
  const handleSave = (e) => {
    e.preventDefault();

    // Regex to validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    const billingData = {
      companyName,
      phoneNumber,
      email,
      addressLine1,
      addressLine2,
      city: city?.value || "",
      state: state?.value || "",
      pincode,
      gstNumber,
      panNumber,
      defaultBillingChecked,
    };

    if (defaultBillingChecked) {
      // Set all others to false if the current one is default
      billingInfo.forEach((info) => (info.defaultBillingChecked = false));
    }

    if (editIndex !== null) {
      // Update existing billing info
      const updatedBillingInfo = [...billingInfo];
      updatedBillingInfo[editIndex] = billingData;
      setBillingInfo(updatedBillingInfo);
    } else {
      // Add new billing info
      setBillingInfo((prevInfo) => [...prevInfo, billingData]);
    }

    // Reset editing state and close modal
    setEditIndex(null);
    setShowModal(false);
  };

  // fucntion handle the edit user billing info
  const handleEditBillingInfo = (index) => {
    const info = billingInfo[index];
    console.log("Editing companyName:", info.companyName); // Log here

    // Populate the form fields with the selected billing info
    setEditIndex(index);
    setCompanyName(info.companyName ? info.companyName : "companyname");
    setPhoneNumber(info.phoneNumber || "");
    setEmail(info.email || "");
    setAddressLine1(info.addressLine1 || "");
    setAddressLine2(info.addressLine2 || "");
    setCity(info.city ? { value: info.city, label: info.city } : null);
    setState(info.state ? { value: info.state, label: info.state } : null);
    setPincode(info.pincode || "");
    setGstNumber(info.gstNumber || "");
    setPanNumber(info.panNumber || "");
    setDefaultBillingChecked(info.defaultBillingChecked || false);
    setShowModal(true);
  };

  //  city options
  const cityOptions = [
    { value: "mumbai", label: "Mumbai" },
    { value: "patna", label: "Patna" },
    { value: "noida", label: "Noida" },
  ];

  // state options
  const stateOptions = [
    { value: "maharashtra", label: "Maharashtra" },
    { value: "delhi", label: "Delhi" },
    { value: "bihar", label: "Bihar" },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "200px", // Custom width
    }),
  };

  return (
    <div className={styles.profileContainer}>
      {isIncompleteProfile && (
        <div
          onClick={() => setIsLoginModalOpen(true)}
          className={styles.missingProfile}
        >
          You have not completed your profile! To get proper updates and
          notifications complete your profile.
        </div>
      )}
      <div className={styles.profileDetails}>
        <h1 className={styles.pHeading}>Personal Details</h1>
        <hr className={styles.breakLine}></hr>
        <div className={styles.pdContent}>
          {userProfile ? (
            <>
              <div className={styles.contentContainer}>
                <span className={styles.pdLabel}>Name</span>
                <p className={styles.pdDescription}>{userProfile.name || ""}</p>
              </div>
              <div className={styles.contentContainer}>
                <span className={styles.pdLabel}>Phone</span>
                <p className={styles.pdDescription}>
                  {userProfile.mobile ? `+91 ${userProfile.mobile}` : ""}
                </p>
              </div>
              <div className={styles.contentContainer}>
                <span className={styles.pdLabel}>Email</span>
                <p className={styles.pdDescription}>
                  {userProfile.email || ""}
                </p>
              </div>
              <div className={styles.contentContainer}>
                <span className={styles.pdLabel}>Company</span>
                <p className={styles.pdDescription}>
                  {userProfile.companyName || ""}
                </p>
              </div>
              <div className={styles.contentContainer}>
                <span className={styles.pdLabel}>Type of Company</span>
                <p className={styles.pdDescription}>
                  {userProfile.companyType || ""}
                </p>
              </div>
            </>
          ) : (
            <p className={styles.noDataText}>No profile details available.</p>
          )}
        </div>
      </div>
      {/* billing info section */}
      <div className={styles.billDetails}>
        <div className={styles.billSection}>
          <h1 className={styles.pHeading}>Billing Details</h1>
          <div onClick={toggleModal} className={styles.bdAddition}>
            <span className={styles.addNewText}>Add New</span>
            <FiPlus />
          </div>
        </div>
        <hr className={styles.breakLine}></hr>
        <div className={styles.billingInfoContainer}>
          {billingInfo.length > 0 ? (
            <>
              {billingInfo.map((info, index) => (
                <div key={index} className={styles.billInfo}>
                  <div className={styles.billUserInfo}>
                    <input
                      type="radio"
                      value={index}
                      name="billingInfo"
                      checked={selectedOption === index.toString()}
                      onChange={() => setSelectedOption(index.toString())}
                    />
                    <div className={styles.bsiName}>
                      <p className={styles.username}>{info.companyName}</p>
                      <div className={styles.emailPhone}>
                        <span>{info.phoneNumber}</span>
                        <p className={styles.bullet}>{info.email}</p>
                      </div>
                    </div>
                    <div
                      onClick={() => handleEditBillingInfo(index)}
                      className={styles.edit}
                    >
                      <FiEdit cursor="pointer" color="#464646" size={18} />
                    </div>
                  </div>
                  <div className={styles.gstpanContainer}>
                    <div className={styles.gstpanBillInfo}>
                      <p className={styles.gstpanHeading}>GSTIN:</p>
                      <span className={styles.gstpanNumber}>
                        {info.gstNumber || "N/A"}
                      </span>
                    </div>
                    <div className={styles.gstpanBillInfo}>
                      <p className={styles.gstpanHeading}>PAN:</p>
                      <span className={styles.gstpanNumber}>
                        {info.panNumber || "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <p className={styles.noDataText}>
              No data found. Please add the billing info.
            </p>
          )}
        </div>
      </div>
      {/* billing info popup modal */}
      {showModal && (
        <form onSubmit={handleSave} className={styles.modalBackdrop}>
          <div className={styles.modalBackdrop} onClick={toggleModal}>
            <div
              className={styles.modalContent}
              onClick={(e) => e.stopPropagation()} // Prevent backdrop click from closing modal
            >
              <div className={styles.modalHeader}>
                <p className={styles.headerText}>Add Billing Information</p>
                <IoIosClose
                  onClick={() => setShowModal(false)}
                  color="#000"
                  cursor="pointer"
                  size={32}
                />
              </div>
              <div className={styles.billingForm}>
                <CustomInput
                  fontSize="16px"
                  marginTop="5px"
                  paddingLeft="22px"
                  labelOpcaity="30%"
                  fontSizeLabel="14px"
                  border="1px solid #98999C"
                  labelTop="15px"
                  fontLabelColor="#98999C"
                  height="50px"
                  placeholder="Company / Your Name"
                  borderRadius="8px"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
                <div className={styles.phoneInputProfile}>
                  <select className={styles.countryCode}>
                    <option value="+91">+91</option>
                  </select>
                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    className={styles.phoneNumber}
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    maxLength={10}
                  />
                </div>
                <CustomInput
                  fontSize="16px"
                  marginTop="5px"
                  paddingLeft="22px"
                  labelOpcaity="30%"
                  fontSizeLabel="14px"
                  border="1px solid #98999C"
                  labelTop="15px"
                  fontLabelColor="#98999C"
                  height="50px"
                  placeholder="Email"
                  borderRadius="8px"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <CustomInput
                  fontSize="16px"
                  marginTop="5px"
                  paddingLeft="22px"
                  labelOpcaity="30%"
                  fontSizeLabel="14px"
                  border="1px solid #98999C"
                  labelTop="15px"
                  fontLabelColor="#98999C"
                  height="50px"
                  placeholder="Address Line 1"
                  borderRadius="8px"
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                />
                <CustomInput
                  fontSize="16px"
                  marginTop="5px"
                  paddingLeft="22px"
                  labelOpcaity="30%"
                  fontSizeLabel="14px"
                  border="1px solid #98999C"
                  labelTop="15px"
                  fontLabelColor="#98999C"
                  height="50px"
                  placeholder="Address Line 2"
                  borderRadius="8px"
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                />
                <div className={styles.state}>
                  <Select
                    value={city}
                    onChange={setCity}
                    styles={customStyles}
                    options={cityOptions}
                  />
                  <Select
                    value={state}
                    onChange={setState}
                    styles={customStyles}
                    options={stateOptions}
                  />
                </div>
                <CustomInput
                  fontSize="16px"
                  marginTop="5px"
                  paddingLeft="22px"
                  labelOpcaity="30%"
                  fontSizeLabel="14px"
                  border="1px solid #98999C"
                  labelTop="15px"
                  fontLabelColor="#98999C"
                  height="50px"
                  placeholder="Pincode"
                  borderRadius="8px"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                />
                <div className={styles.gstpan}>
                  <CustomInput
                    fontSize="16px"
                    marginTop="5px"
                    paddingLeft="22px"
                    labelOpcaity="30%"
                    fontSizeLabel="14px"
                    border="1px solid #98999C"
                    labelTop="15px"
                    fontLabelColor="#98999C"
                    height="50px"
                    placeholder="GSTIN Number"
                    borderRadius="8px"
                    value={gstNumber}
                    onChange={(e) => setGstNumber(e.target.value)}
                  />
                  <CustomInput
                    fontSize="16px"
                    marginTop="5px"
                    paddingLeft="22px"
                    labelOpcaity="30%"
                    fontSizeLabel="14px"
                    border="1px solid #98999C"
                    labelTop="15px"
                    fontLabelColor="#98999C"
                    height="50px"
                    placeholder="PAN Number"
                    borderRadius="8px"
                    value={panNumber}
                    onChange={(e) => setPanNumber(e.target.value)}
                  />
                </div>
                <div className={styles.billingCheckbox}>
                  <label className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      checked={defaultBillingChecked}
                      onChange={handleBillCheckboxChange}
                      className={styles.checkbox}
                    />
                    <span className={styles.checkboxText}>
                      Set as default billing information
                    </span>
                  </label>
                </div>
                <div className={styles.saveBtn}>
                  <CustomButton
                    text="Save"
                    backgroundColor="var(--button-primary)"
                    borderRadius="50px"
                    width="399px"
                    height="50px"
                    textColor="#fff"
                    fontSize="18px"
                    fontFamily="Raleway"
                    fontWeight="550"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      <Login
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
      />
    </div>
  );
};

export default MyProfile;
