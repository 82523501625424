import React, { useEffect, useState } from "react";
import "../styles/Home.css";
import styles from "../styles/Home.module.css";
// import { FaRegHeart } from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
// import { RiShoppingCart2Line } from "react-icons/ri";
import config from "../utils/config";
import CustomButton from "../components/CustomButton/CustomButton";
import AllTrackCards from "../components/CardsComponent/TrackCard/AllTrackCards";
// import PackCard from "../components/CardsComponent/PackCard/PackCard";
import PlaylistCard from "../components/CardsComponent/PlaylistCard/PlaylistCard";
import { Link } from "react-router-dom";
import randomDefaultImage from "../utils/RandomDefaultImage";
import TrackCard from "../components/CardsComponent/TrackCard/TrackCard";
import { getMultiTrack } from "../service/TrackService";
import getDirectLink from "../utils/AudioUrlFormated";
import {
  remoteConfig,
  fetchAndActivate,
  getValue,
} from "../utils/firebase/remoteConfig";
import CustomImg from "../components/CustomImg/CustomImg";
import BgImageDiv from "../components/BgImageDiv/BgImageDiv";
import setMetaTags from "../utils/SeoService";
import Carousel from "../components/Carousel/Carousel";

const Home = () => {
  // const [bannerPopup, setBannerPopup] = useState(false); // to display know more banner
  const [featureTracks, setFeatureTracks] = useState([]); // feature tracks data
  const [contemporaryTracks, setContemporaryTracks] = useState([]); // contemporary track data
  const [indieTracks, setIndieTracks] = useState([]); // indie tracks
  const [allTimeTracks, setAllTimeTracks] = useState([]); // all time tracks
  const [instaTracks, setInstaTracks] = useState([]); // instaTracks

  // top head banners with filters
  const priceFilters = {
    min: 4999,
    max: "",
  };

  // Function to encode object to Base64
  const encodeFilters = (filters) => {
    return btoa(JSON.stringify(filters));
  };
  const mainBanner = [
    {
      image: `${config.s3imgUrl}web/home/license-l1.png`,
      buttonText: "Watch Video",
      buttonLink: "/about",
    },
    {
      image: `${config.s3imgUrl}web/home/bolly-p.webp`,
      buttonText: "View Playlist",
      buttonLink: "/playlists/bolly/14",
    },
    {
      image: `${config.s3imgUrl}web/home/valentines-p.webp`,
      buttonText: "Explore Tracks",
      buttonLink: "/search?searchKeyword=valentine's day",
    },
    {
      image: `${config.s3imgUrl}web/home/bohemia-p.webp`,
      buttonText: "Explore Tracks",
      buttonLink: "/search?searchKeyword=bohemia",
    },
    {
      image: `${config.s3imgUrl}web/home/wedding.jpg`,
      buttonText: "Explore Tracks",
      // buttonLink: "/search?searchKeyword=wedding",
      buttonLink: `/search?searchKeyword=wedding&priceFilters=${encodeFilters(
        priceFilters
      )}`,
    },
  ];

  // State to track window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Effect to update width on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Check if it's mobile or tablet
  const isMobile = windowWidth <= 425;
  const isTablet = windowWidth <= 768;

  // setting the seo meta on page renders
  useEffect(() => {
    const seoData = {
      title: "Music Licensing for Video, Film & Advertising | Hoopr Smash",
      description:
        "License songs for video, film, and advertising on Hoopr Smash. Access a wide range of music tracks to enhance your projects, with easy and flexible licensing options.",
      ogImage: `${config.s3imgUrl}web/logos/smash.png`,
      canonical: window.location.href,
      // lang: "en",
    };
    setMetaTags(seoData);
  }, []);

  // fetching the remote config file from the firebase
  useEffect(() => {
    const fetchRemoteConfig = async () => {
      try {
        // Fetch and activate remote config values
        await fetchAndActivate(remoteConfig); // This waits for the config to be fetched

        // Retrieve the JSON string from Remote Config
        const jsonString = getValue(remoteConfig, "Home_Config").asString();

        // Parse the JSON string into a JavaScript object
        const parsedConfig = JSON.parse(jsonString);
        // console.log("Parsed Config:", parsedConfig);
        // console.log("Tracks Data:", parsedConfig.tracks);
        processTracks(parsedConfig.tracks);
      } catch (error) {
        console.error("Error fetching remote config:", error);
      }
    };

    fetchRemoteConfig();
  }, []);

  // Process the tracks and filter by type
  const processTracks = (tracks) => {
    // Filter and convert allTimeTracks to string codes
    const allinstaTrackCodes = tracks
      .filter((track) => track.type === "instaTracks")
      .map((track) => String(track.trackCode)); // Convert to string
    // Filter and convert featureTracks to string codes
    const featureTrackCodes = tracks
      .filter((track) => track.type === "featureTracks")
      .map((track) => String(track.trackCode)); // Convert to string

    // Filter and convert contemporaryTracks to string codes
    const contemporaryTrackCodes = tracks
      .filter((track) => track.type === "contemporaryTracks")
      .map((track) => String(track.trackCode)); // Convert to string

    // Filter and convert indieTracks to string codes
    const indieTrackCodes = tracks
      .filter((track) => track.type === "indieTracks")
      .map((track) => String(track.trackCode)); // Convert to string

    // Filter and convert allTimeTracks to string codes
    const allTimeTrackCodes = tracks
      .filter((track) => track.type === "allTimeTracks")
      .map((track) => String(track.trackCode)); // Convert to string

    // Fetch data for feature tracks and update state incrementally
    fetchTrackDataIncremental(allinstaTrackCodes, "instaTracks");

    // Fetch data for feature tracks and update state incrementally
    fetchTrackDataIncremental(featureTrackCodes, "featureTracks");

    // Fetch data for contemporary tracks and update state incrementally
    fetchTrackDataIncremental(contemporaryTrackCodes, "contemporaryTracks");

    // Fetch data for indie tracks and update state incrementally
    fetchTrackDataIncremental(indieTrackCodes, "indieTracks");

    // Fetch data for all time tracks and update state incrementally
    fetchTrackDataIncremental(allTimeTrackCodes, "allTimeTracks");
  };

  // Fetch track data incrementally based on track codes with order
  const fetchTrackDataIncremental = async (trackCodes, trackType) => {
    const fetchPromises = trackCodes.map(async (trackCode) => {
      const trackData = await getMultiTrack([trackCode]); // Fetch data for a single track

      const track = trackData?.data?.tracks?.[0];
      if (!track) return null; // Skip if track data is invalid

      const { costPrice, sellingPrice, id } = track.SKU[0];
      const discountPercent = (
        ((costPrice - sellingPrice) / costPrice) *
        100
      ).toFixed(0);

      const artistNames = track.primaryArtists?.length
        ? track.primaryArtists.map((artist) => artist.artistName).join(", ")
        : "";

      // Process and return the track with the order field
      return {
        order: track.order, // Ensure `order` exists in the track object
        // image: track.imageUrl || randomDefaultImage("track"),
        title:
          track.name.length > 18
            ? track.name.substring(0, 17) + "..."
            : track.name,
        description:
          artistNames.length > 30
            ? artistNames.substring(0, 29) + "..."
            : artistNames,
        buttonText: "Explore",
        buttonLink: "#",
        discountPercent: discountPercent || "50% OFF",
        costPrice: costPrice.toLocaleString("en-IN") || "",
        sellingPrice: sellingPrice.toLocaleString("en-IN") || "",
        name: track.name_slug,
        trackCode: track.trackCode,
        trackUrl: track.mp3Link || getDirectLink(track.link),
        waveJson: track.waveformLink,
        skuId: id || "",
      };
    });

    // Process tracks incrementally as they resolve
    for await (const processedTrack of fetchPromises) {
      if (processedTrack) {
        const updateState = (prev) =>
          Array.from(
            new Map(
              [...prev, processedTrack].map((track) => [track.trackCode, track])
            )
          )
            .map(([, value]) => value)
            .sort((a, b) => a.order - b.order); // Sort based on `order`

        if (trackType === "featureTracks") {
          // console.log(featureTracks,"featureTracks");

          setFeatureTracks(updateState);
        } else if (trackType === "contemporaryTracks") {
          setContemporaryTracks(updateState);
        } else if (trackType === "indieTracks") {
          setIndieTracks(updateState);
        } else if (trackType === "allTimeTracks") {
          setAllTimeTracks(updateState);
        } else if (trackType === "instaTracks") {
          setInstaTracks(updateState);
        }
      }
    }
  };

  // for know more image banners
  // const imagePoints = ["Point1.png", "Point2.png", "Point3.png"];

  const catEssentials = [
    {
      image: `${config.s3imgUrl}web/playlists/59.webp`,
      title: "Cooking - Ethnic",
      buttonText: "Explore",
      buttonLink: "#",
      totalTracks: 4,
      packCode: 59,
      packNameSlug: "cooking",
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
    },
    {
      image: `${config.s3imgUrl}web/playlists/68.webp`,
      title: "Ethnic Jewellery",
      buttonText: "Explore",
      buttonLink: "#",
      totalTracks: 5,
      packCode: 68,
      packNameSlug: "beauty-wellness",
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
    },
    {
      image: `${config.s3imgUrl}web/playlists/72.webp`,
      title: "Incredible India",
      buttonText: "Explore",
      buttonLink: "#",
      totalTracks: 5,
      packCode: 72,
      packNameSlug: "india",
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
    },
    {
      image: `${config.s3imgUrl}web/playlists/80.webp`,
      title: "Art & Craft",
      buttonText: "Explore",
      buttonLink: "#",
      totalTracks: 5,
      packCode: 80,
      packNameSlug: "art-and-craft",
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
    },
    {
      image: `${config.s3imgUrl}web/playlists/82.webp`,
      title: "EVs Exclusive",
      buttonText: "Explore",
      buttonLink: "#",
      totalTracks: 5,
      packCode: 82,
      packNameSlug: "ev-exclusive",
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
    },
    {
      image: `${config.s3imgUrl}web/playlists/96.webp`,
      title: "Active Wear",
      buttonText: "Explore",
      buttonLink: "#",
      totalTracks: 5,
      packCode: 96,
      packNameSlug: "active-wear",
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
    },
  ];

  const packCards = [
    {
      image: `${config.s3imgUrl}web/playlists/6.webp`,
      title: "Reels Mania",
      buttonText: "Explore",
      buttonLink: "/playlists/pack-reels-mania/6",
      totalTracks: 5,
      packCode: 6,
      packNameSlug: "reels-mania",
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
    },
    {
      image: `${config.s3imgUrl}web/playlists/12.webp`,
      title: "Trendsetters",
      buttonText: "Explore",
      buttonLink: "/playlists/pack-trendsetters/12",
      totalTracks: 5,
      packCode: 12,
      packNameSlug: "trendsetters",
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
    },
    {
      image: `${config.s3imgUrl}web/playlists/13.webp`,
      title: "Happily Ever After",
      buttonText: "Explore",
      buttonLink: "/playlists/pack-happily-ever-after/13",
      totalTracks: 5,
      packCode: 13,
      packNameSlug: "happily-ever-after",
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
    },
    {
      image: `${config.s3imgUrl}web/playlists/8.webp`,
      title: "Year End Celebrations",
      buttonText: "Explore",
      buttonLink: "/playlists/pack-year-end-celebrations/8",
      totalTracks: 3,
      packCode: 8,
      packNameSlug: "year-end-celebrations",
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
    },
    {
      image: `${config.s3imgUrl}web/playlists/11.webp`,
      title: "Love Is In The Air",
      buttonText: "Explore",
      buttonLink: "/playlists/pack-love-is-in-the-air/11",
      totalTracks: 5,
      packCode: 11,
      packNameSlug: "love-is-in-the-air",
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
    },
    {
      image: `${config.s3imgUrl}web/playlists/7.webp`,
      title: "Where's The Party?",
      buttonText: "Explore",
      buttonLink: "/playlists/pack-wheres-the-party/7",
      totalTracks: 5,
      packCode: 7,
      packNameSlug: "wheres-the-party",
      sellingPrice: "99999",
      costPrice: "129999",
      discountPercent: "23% Off",
    },
  ];

  const categoryCards = [
    {
      name: "Fashion",
      slug: "fashion",
      popular: "true",
      id: "4d12fd79-69b5-4482-9a7d-61cfe521439c",
    },
    {
      name: "Food",
      slug: "food",
      popular: "false",
      id: "d096a310-adf6-4974-8ff6-3ca8e5d0e55c",
    },
    {
      name: "Travel",
      slug: "travel",
      popular: "true",
      id: "33b2bf23-f5b5-4398-aa51-7046a2922691",
    },
    {
      name: "Health & Fitness",
      slug: "health-and-fitness",
      popular: "false",
      id: "4f4624b6-9f8a-458d-82d1-77a844691856",
    },
    {
      name: "Automobile",
      slug: "autombile",
      popular: "false",
      id: "5b53b4ff-a802-4b5d-bcef-6ec3fcd727c8",
    },
  ];

  const featuredMoods = [
    {
      name: "Romantic",
      slug: "romantic",
      popular: "false",
      id: "ed820c8e-03f5-43d1-bbd8-c96c51b31f88",
    },
    {
      name: "Motivational",
      slug: "motivational",
      popular: "false",
      id: "7e9caf70-1cd5-4e82-8ee0-8f4590f2f8bb",
    },
    {
      name: "Ethnic",
      slug: "ethnic",
      popular: "false",
      id: "7e98f210-b995-46f0-973c-0da8e6713c17",
    },
    {
      name: "Dreamy",
      slug: "dreamy",
      popular: "false",
      id: "6a011e8c-5048-4448-bce2-5458dadf1421",
    },
    {
      name: "Action",
      slug: "action",
      popular: "false",
      id: "9adc4829-f074-40cc-a9d6-12263b78bcb0",
    },
    {
      name: "Nostalgic",
      slug: "nostalgic",
      popular: "false",
      id: "ad8e3c56-3a40-409d-b1c5-01e9a35a6e23",
    },
  ];

  const featuredGenres = [
    {
      name: "Bollywood",
      slug: "bollywood",
      popular: "false",
      id: "3e3cb3f5-fcf0-4e4d-a5b9-ea1d6c2cf4cc",
    },
    {
      name: "Indian Folk",
      slug: "indian-folk",
      popular: "false",
      id: "0c29a125-b83e-4b4b-8208-ac8b7e810e2b",
    },
    {
      name: "Kids",
      slug: "kids",
      popular: "false",
      id: "31026e90-b30c-4b15-ae87-be7fbd924b1a",
    },
  ];

  // const offerCards = [
  //   {
  //     image: `${config.s3imgUrl}trackimages/sampleyrf.png`,
  //     title: "Offers & Discounts",
  //     description:
  //       "Don't miss out on this trend-setting opportunity! For a limited time...",
  //     buttonText: "Explore Tracks",
  //   },
  //   {
  //     image: `${config.s3imgUrl}trackimages/samplepopularsong.png`,
  //     title: "Extra 10% OFF on Popular Songs",
  //     description:
  //       "Don't miss out on this trend-setting opportunity! For a limited time, you can...",
  //     buttonText: "Explore Tracks",
  //   },
  // ];

  // const getTracks = async (trackCodes, type) => {
  //   const trackData = await getMultiTrack(trackCodes);

  //   const processedTracks = trackData?.data?.tracks?.map((track) => {
  //     const { costPrice, sellingPrice } = track.SKU[0];
  //     const discountPercent = (
  //       ((costPrice - sellingPrice) / costPrice) *
  //       100
  //     ).toFixed(0);

  //     const artistNames = track.primaryArtists?.length
  //       ? track.primaryArtists.map((artist) => artist.artistName).join(", ")
  //       : "";

  //     return {
  //       image: track.imageUrl || randomDefaultImage("track"),
  //       title:
  //         track.name.length > 18
  //           ? track.name.substring(0, 17) + "..."
  //           : track.name,
  //       description:
  //         artistNames.length > 30
  //           ? artistNames.substring(0, 29) + "..."
  //           : artistNames,
  //       buttonText: "Explore",
  //       buttonLink: "#",
  //       discountPercent: discountPercent || "50% OFF",
  //       costPrice: costPrice.toLocaleString("en-IN") || "19,999",
  //       sellingPrice: sellingPrice.toLocaleString("en-IN") || "79,999",
  //       name: track.name_slug,
  //       trackCode: track.trackCode,
  //       trackUrl:
  //         track.mp3Link || getDirectLink(track.link) || track.waveformLink,
  //     };
  //   });

  //   if (type === "featureTracks") {
  //     setFeatureTracks(processedTracks);
  //   }
  //   // else if (type === "trendingTracks") {
  //   //   // setTrendingTracks(processedTracks);
  //   // }
  // };

  // const DemoIG = () => {
  //   return (
  //     <div
  //       className="IG-embed"
  //       dangerouslySetInnerHTML={{ __html: ig_embed }}
  //     ></div>
  //   );
  // };

  //fucntion to display discount banner modal
  // const handleBannerModal = () => {
  //   setBannerPopup(!bannerPopup);
  // };

  return (
    <div className={styles.parentContainer}>
      {/* banner section */}
      <div className={`${styles.outer} ${styles.bannerContainer}`}>
        <Carousel gap={10} autoScroll={false} scrollInterval={4000}>
          {mainBanner.map((banner, index) => (
            <Link to={banner.buttonLink}>
              <div
                key={index}
                style={{
                  backgroundImage: `url(${banner.image})`,
                }}
                className={styles.bannerCardImg}
              >
                {/* {index !== 0 && (
                  <CustomButton
                    text={banner.buttonText}
                    backgroundColor={
                      index == 0 ? "var(--button-primary)" : "#FFF"
                    }
                    borderRadius="50px"
                    borderColor={index == 0 ? "none" : "var(--button-primary)"}
                    width="190px"
                    height="44px"
                    textColor={index == 0 ? "#FFF" : "var(--button-primary)"}
                    iconColor={index == 0 ? "#FFF" : "var(--button-primary)"}
                    fontSize="16px"
                    fontFamily="Inter"
                    fontWeight="bold"
                    icon={<IoIosArrowForward />}
                    justifyContent="space-between"
                  />
                )} */}

                <CustomButton
                  text={banner.buttonText}
                  // backgroundColor={
                  //   index == 0 ? "var(--button-primary)" : "#FFF"
                  // }
                  // backgroundColor="none"
                  borderRadius="50px"
                  // borderColor={index == 0 ? "1px solid var(--button-primary)" : "var(--button-primary)"}
                  // borderColor="1px solid var(--button-primary)"
                  borderColor={
                    index == 0
                      ? "1px solid var(--button-primary)"
                      : "2px solid #FFFFFF55"
                  }
                  width="190px"
                  height="44px"
                  textColor={index == 0 ? "var(--button-primary)" : "#FFF"}
                  iconColor={index == 0 ? "var(--button-primary)" : "#FFF"}
                  fontSize="16px"
                  fontFamily="Inter"
                  fontWeight="bold"
                  icon={<IoIosArrowForward />}
                  justifyContent="space-between"
                />
              </div>
            </Link>
          ))}
        </Carousel>
      </div>
      {/* MARKETING BANNER 1 */}
      <div className={`${styles.mktBanner2Container} ${styles.outer}`}>
        <Link to={"/tracks"}>
          {/* <CustomImg
          type="genre"
          id="discountBanner"
          alt="Know more about licensing"
          imgClass=""
          // src={`${config.s3imgUrl}web/promotional/tracks/b-ott.webp`}
          src={`${config.s3imgUrl}web/home/mkt-90.webp`}
        /> */}
          <video
            autoPlay
            muted
            loop
            playsInline
            disablePictureInPicture
            disableRemotePlayback
            className={styles.abtSearchImgL}
          >
            <source
              src={`${config.s3imgUrl}web/home/mkt-top-banner.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </Link>
      </div>
      {/* know more banner */}
      {/* <div
        className="discountBanner-container outer"
        onClick={handleBannerModal}
      >

        <CustomImg
          type="genre"
          id="discountBanner"
          alt="Know more about licensing"
          imgClass=""
          src={`${config.s3imgUrl}web/home/know-more.png`}
        />

        {bannerPopup && (
          <div className="modal-overlay">
            <div className="modal-content">
              <button className="modal-close-btn" onClick={handleBannerModal}>
                &times;
              </button>
              <h2>
                License Popular Bollywood & Trending Tracks in 3 Simple Steps
              </h2>
              <div className="modal-banner-img-container">
                {imagePoints.map((imageName, index) => (
                  <div key={index} className="model-banner-img">
                    <img
                      src={`${config.s3imgUrl}web/home/knowmore/${imageName}`}
                      alt={`Banner ${index + 1}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div> */}
      {/* instagram trending track */}
      <div
        className={`${styles.trackParentContainer} ${styles.outer} ${styles.instaContainer}`}
      >
        <div className={styles.inner}>
          <div className={styles.tpHeader}>
            <h1 className={styles.tpHeadertext}>
              Instagram Trending Tracks
              <span className={styles.discountR}>Upto 55% off</span>
            </h1>
          </div>
          <div className={styles.tpSubHeader}>
            <h2 className={styles.tpSubHeaderText}>
              License music best suited for present-day tastes
            </h2>
            <Link to={"/playlists/trending-on-social-media/105"}>
              <div className={styles.viewall}>
                <p className={styles.viewalltext}>View All</p>
                <p className={styles.viewalltext}>
                  <IoIosArrowForward color="#373737" />
                </p>
              </div>
            </Link>
          </div>
          <div className={styles.trackCards}>
            {/* <TrackCard
              trackCard={instaTracks}
              btnWidth={isMobile ? "146px" : isTablet ? "168px" : "186px"}
              trackType="contemporary"
            /> */}
            <TrackCard
              trackCard={
                windowWidth <= 481 ? instaTracks.slice(0, 4) : instaTracks
              }
              // trackCard={contemporaryTracks}
              btnWidth={
                isMobile
                  ? windowWidth <= 320
                    ? "80px"
                    : windowWidth <= 375
                    ? "110px"
                    : "110px"
                  : isTablet
                  ? windowWidth <= 430
                    ? "110px"
                    : "168px"
                  : "186px"
              }
              // btnWidth={isMobile ? "130px" : isTablet ? "168px" : "186px"}
              trackType="contemporary"
            />
          </div>
        </div>
      </div>
      {/* Contemporary Container */}
      <div
        className={`${styles.trackParentContainer} ${styles.outer} ${styles.containerHeight}`}
      >
        <div className={styles.inner}>
          <div className={styles.tpHeader}>
            <h1 className={styles.tpHeadertext}>
              Contemporary Hits
              <span className={styles.discountR}>Upto 55% off</span>
              {/* <span>
                <img
                  className="trending-icon-header"
                  src={`${config.s3imgUrl}icons/trendwhiteicon.png`}
                  alt="trending"
                />
              </span> */}
            </h1>
          </div>
          <div className={styles.tpSubHeader}>
            <h2 className={styles.tpSubHeaderText}>
              License music best suited for present-day tastes
            </h2>
            <Link to={"/playlists/contemporary-hits/107"}>
              <div className={styles.viewall}>
                <p className={styles.viewalltext}>View All</p>
                <p className={styles.viewalltext}>
                  <IoIosArrowForward color="#373737" />
                </p>
              </div>
            </Link>
          </div>
          <div className={styles.trackCards}>
            <TrackCard
              trackCard={
                windowWidth <= 481
                  ? contemporaryTracks.slice(0, 4)
                  : contemporaryTracks
              }
              // trackCard={contemporaryTracks}
              btnWidth={
                isMobile
                  ? windowWidth <= 320
                    ? "80px"
                    : windowWidth <= 375
                    ? "110px"
                    : "110px"
                  : isTablet
                  ? windowWidth <= 430
                    ? "110px"
                    : "168px"
                  : "186px"
              }
              // btnWidth={isMobile ? "130px" : isTablet ? "168px" : "186px"}
              trackType="contemporary"
            />
          </div>
        </div>
      </div>
      {/* marketing banner 2 section */}
      <div className={`${styles.mktBanner2Container} ${styles.outer}`}>
        <Link to={"/tracks"}>
          <CustomImg
            type="genre"
            id="discountBanner"
            alt="Know more about licensing"
            imgClass={styles.mkt2image}
            src={`${config.s3imgUrl}web/promotional/tracks/b-ott.webp`}
          />
        </Link>
      </div>
      {/* CATEGORY ESSENTIALS */}
      <div className={`${styles.packsContainer} ${styles.outer}`}>
        <div style={{ margin: 0 }} className={styles.inner}>
          <h1 className={styles.packsHeading}>
            Category Essentials
            <span className={styles.discount}>Upto 55% off</span>
          </h1>
          <div className={styles.packHeaderContainer}>
            <h2 className={styles.packSubHeading}>
              Buy specially curated thematic, multi-track packs and enjoy great
              savings
            </h2>
            <a href="/packs">
              <div className={styles.viewall}>
                <p className={styles.viewalltext}>View All Packs</p>
                <p>
                  <IoIosArrowForward color="#373737" />
                </p>
              </div>
            </a>
          </div>
          {/* Apply Carousel only for Mobile & Tablet (≤781px) */}
          {windowWidth <= 481 ? (
            <Carousel gap={15} autoScroll={false} scrollInterval={4000}>
              {catEssentials.map((pack, index) => (
                <PlaylistCard
                  key={index}
                  packCards={[pack]}
                  packType="toppackhome"
                />
              ))}
            </Carousel>
          ) : (
            <PlaylistCard packCards={catEssentials} packType="toppackhome" />
          )}
          {/* <PlaylistCard packCards={catEssentials} packType="toppackhome" /> */}
        </div>
      </div>
      {/* INDIE CONTAINER */}
      <div
        style={{ backgroundColor: "#fff" }}
        className={`${styles.trackParentContainer} ${styles.outerFull} ${styles.containerHeight} ${styles.bgBlue} ${styles.indiContainer} ${styles.mobile}`}
      >
        <div className={styles.inner}>
          <div className={styles.tpHeader}>
            <h1 className={`${styles.tpHeadertext} ${styles.indieHeading}`}>
              Indie Hits <span className={styles.discountR}>Upto 55% off</span>
              {/* <span>
                <img
                  className="trending-icon-header"
                  src={`${config.s3imgUrl}icons/trendwhiteicon.png`}
                  alt="trending"
                />
              </span> */}
            </h1>
          </div>
          <div className={styles.tpSubHeader}>
            <h2 className={styles.tpSubHeaderText}>
              Hits delivered by a slew of independent artists
            </h2>
            <Link to={"/playlists/independent-hits/104"}>
              <div className={styles.viewall}>
                <p className={`${styles.viewalltext} ${styles.viewWhitep}`}>
                  View All
                </p>
                <p className={styles.viewalltext}>
                  <IoIosArrowForward color="#FFF" />
                </p>
              </div>
            </Link>
          </div>
          <div className={styles.trackCards}>
            <TrackCard
              trackCard={
                windowWidth <= 481 ? indieTracks.slice(0, 4) : indieTracks
              }
              // trackCard={indieTracks}
              btnWidth={
                isMobile
                  ? windowWidth <= 320
                    ? "80px"
                    : windowWidth <= 375
                    ? "110px"
                    : "110px"
                  : isTablet
                  ? windowWidth <= 430
                    ? "110px"
                    : "168px"
                  : "186px"
              }
              // btnWidth={isMobile ? "146px" : isTablet ? "168px" : "186px"}
              trackType="indie"
              cartBgColor="transparent"
              cartIgColor="#000"
              borderColor="1px solid rgb(204, 204, 204)"
            />
          </div>
        </div>
      </div>
      {/* all time hits section */}
      <div
        className={`${styles.trackParentContainer} ${styles.outer} ${styles.containerHeight}`}
      >
        <div className={styles.inner}>
          <div className={styles.tpHeader}>
            <h1 className={styles.tpHeadertext}>
              All Time Hits{" "}
              <span className={styles.discountR}>Upto 55% off</span>
              {/* <span>
                <img
                  className="trending-icon-header"
                  src={`${config.s3imgUrl}icons/trendwhiteicon.png`}
                  alt="trending"
                />
              </span> */}
            </h1>
          </div>
          <div className={styles.tpSubHeader}>
            <h2 className={styles.tpSubHeaderText}>
              License the most iconic Bollywood hits of all time!
            </h2>
            <Link to={"/playlists/nostalgic-2000s/99"}>
              <div className={styles.viewall}>
                <p className={styles.viewalltext}>View All</p>
                <p className={styles.viewalltext}>
                  <IoIosArrowForward color="#373737" />
                </p>
              </div>
            </Link>
          </div>
          <div className={styles.trackCards}>
            <TrackCard
              trackCard={
                windowWidth <= 481 ? allTimeTracks.slice(0, 4) : allTimeTracks
              }
              // trackCard={allTimeTracks}
              btnWidth={
                isMobile
                  ? windowWidth <= 320
                    ? "80px"
                    : windowWidth <= 375
                    ? "110px"
                    : "110px"
                  : isTablet
                  ? windowWidth <= 430
                    ? "110px"
                    : "168px"
                  : "186px"
              }
              // btnWidth={isMobile ? "146px" : isTablet ? "168px" : "186px"}
              trackType="contemporary"
            />
          </div>
        </div>
      </div>
      {/* top packs container */}
      <div className={`${styles.packsContainer} ${styles.outer}`}>
        <div style={{ margin: 0 }} className={styles.inner}>
          <h1 className={styles.packsHeading}>
            Hottest Packs This Season
            <span className={styles.discount}>Upto 70% off</span>
          </h1>
          <div className={styles.packHeaderContainer}>
            <h2 className={styles.packSubHeading}>
              Buy specially curated thematic, multi-track packs and enjoy great
              savings
            </h2>
            <a href="/packs">
              <div className={styles.viewall}>
                <p className={styles.viewalltext}>View All Packs</p>
                <p className={styles.viewalltext}>
                  <IoIosArrowForward color="#373737" />
                </p>
              </div>
            </a>
          </div>
          {/* Apply Carousel only for Mobile & Tablet (≤781px) */}
          {windowWidth <= 481 ? (
            <Carousel gap={20} autoScroll={false} scrollInterval={4000}>
              {packCards.map((pack, index) => (
                <PlaylistCard
                  key={index}
                  packCards={[pack]}
                  packType="toppackhome"
                />
              ))}
            </Carousel>
          ) : (
            <PlaylistCard packCards={packCards} packType="toppackhome" />
          )}
          {/* <PlaylistCard packCards={packCards} packType="toppackhome" /> */}
        </div>
      </div>
      {/* explore by brand category i.e. categories */}
      <div className={`${styles.brandContainer} ${styles.outerFull}`}>
        <div className={styles.inner}>
          <div className={styles.bcHeader}>
            <h1 className={styles.bcHeaderText}>Top Searched Categories</h1>
            <Link to="/categories">
              <div className={styles.bcViewAll}>
                View All <IoIosArrowForward size={18} color="#373737" />
              </div>
            </Link>
            {/* <div className="brand-viewall">
              <Link to={"/categories"}>
                <p>
                  View All <IoIosArrowForward color="#373737" />
                </p>
              </Link>
            </div> */}
          </div>
          <div className={styles.bcCards}>
            {categoryCards
              .slice(0, windowWidth <= 481 ? 4 : 5)
              .map((cat, index) => (
                <Link
                  key={index}
                  to={`/categories/${encodeURIComponent(cat.slug)}/${cat.id}`}
                >
                  <CustomImg
                    type="cat"
                    id={cat.id}
                    alt={cat.name}
                    imgClass={styles.brandCardImg}
                    src=""
                  />
                </Link>
              ))}
          </div>
        </div>
      </div>
      {/* feature moods and genre section */}
      <div className={`${styles.mgContainer} ${styles.outer}`}>
        <div className={`${styles.inner} ${styles.innerMgContainer}`}>
          <div className={styles.genreContainer}>
            <div className={styles.mgHeaderContainer}>
              <h1 className={styles.mgHeaderText}>Top Searched Genres</h1>
              <Link to="/genres">
                <div className={styles.bcViewAll}>
                  View All <IoIosArrowForward size={18} color="#373737" />
                </div>
              </Link>
            </div>
            <div className={styles.genreCardContainer}>
              {featuredGenres.map((genre) => (
                <Link
                  to={`/genres/${encodeURIComponent(genre.slug)}/${genre.id}`}
                >
                  <div key={genre.id} className={styles.mgCards}>
                    <CustomImg
                      type="genre"
                      id={genre.id}
                      alt={genre.name}
                      imgClass={styles.genreCardImg}
                      src=""
                    />
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className={styles.moodContainer}>
            <div className={styles.mgHeaderContainer}>
              <h1 className={styles.mgHeaderText}>Top Searched Moods</h1>
              <Link to="/moods">
                <div className={styles.bcViewAll}>
                  View All <IoIosArrowForward size={18} color="#373737" />
                </div>
              </Link>
            </div>
            <div className={styles.moodCardContainer}>
              {featuredMoods.map((mood) => (
                <Link to={`/moods/${encodeURIComponent(mood.slug)}/${mood.id}`}>
                  <div key={mood.id} className={styles.mgCards}>
                    <BgImageDiv
                      type="mood"
                      id={mood.id}
                      divClass={styles.moodCardImg}
                    ></BgImageDiv>
                    <p className={styles.moodCardHeading}>{mood.name}</p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* feature tracks section */}
      <div
        className={`${styles.ftContainer} ${styles.outerFull} ${styles.ftmobile}`}
      >
        <div className={styles.inner}>
          <h1 className={`${styles.ftHeading}`}>
            Featured Tracks
            <span className={styles.discountR}>Upto 55% off</span>
          </h1>
          <div className={styles.tpSubHeader}>
            {/* <h2>On your first Instagram Trending Track Purchase</h2> */}
            {/* <div className="feature-track-viewall">
              <p>View All</p>
              <p>
                <IoIosArrowForward color="#fff" />
              </p>
            </div> */}
          </div>
          <div style={{ paddingBottom: 20 }} className={styles.trackCards}>
            <TrackCard
              trackCard={
                windowWidth <= 481 ? featureTracks.slice(0, 4) : featureTracks
              }
              // trackCard={featureTracks}
              btnWidth={
                isMobile
                  ? windowWidth <= 320
                    ? "80px"
                    : windowWidth <= 375
                    ? "110px"
                    : "110px"
                  : isTablet
                  ? windowWidth <= 430
                    ? "110px"
                    : "168px"
                  : "186px"
              }
              // btnWidth={isMobile ? "146px" : isTablet ? "168px" : "186px"}
              trackType="feature"
              cartBgColor="#fff"
              cartIgColor="#000"
            />
          </div>
        </div>
      </div>
      {/* all songs container */}
      <div className={styles.allSongs}>
        <AllTrackCards viewall="true" />
      </div>
    </div>
  );
};

export default Home;
