const moods = {
    // "f11b4beb-c2de-41a1-81d3-1ca0dd870110": {
    //     "name": "Abstract",
    //     "description": "Ethereal and experimental soundscapes, perfect for creative and avant-garde projects.",
    // },
    "9adc4829-f074-40cc-a9d6-12263b78bcb0": {
        "name": "Action",
        "description": "Fast, fierce, and full of adrenaline—action music is all about intensity! With pounding drums, driving basslines, and electrifying riffs, this music builds excitement and keeps viewers on edge. Ideal for automobile ads, gaming promos, sports highlights, fitness brands, and high-energy product launches. Whether you're showing off a sleek new car, an extreme sports event, or a bold new tech gadget, action-packed music delivers the punch. Need a soundtrack that keeps up with the pace? These Bollywood beats are fully copyright-safe and can be effortlessly licensed for your marketing needs. You can go full throttle without any worries.",
    },
    "89f693a7-6b5c-40a9-94e9-719caf72a963": {
        "name": "Adventurous",
        "description": "Let’s hit the road and explore! Adventurous music brings a sense of excitement, wanderlust, and bold discovery. Think grand orchestras, rhythmic drums, and uplifting melodies that inspire movement. Ideal for travel vlogs, tourism campaigns, outdoor gear brands, and storytelling-driven commercials. Whether it's a thrilling expedition, a road trip montage, or a brand that embodies adventure, the right soundtrack takes the journey to the next level. Ready to explore new horizons? Our collection of Bollywood and trending chart-toppers is copyright-safe and licensing is a breeze! So your short-form promotional videos can be as epic as the journey itself.",
    },
    // "e29621c0-09f0-44d1-af32-aa8c7ba52d0e": {
    //     "name": "Aggressive",
    //     "description": "",
    // },
    "de78fff8-b2cd-4758-92c6-7c7b182ff2f1": {
        "name": "Angry",
        "description": "Raw, powerful, and unfiltered—angry music brings the heat with heavy guitars, intense beats, and aggressive rhythms. This is the sound of rebellion, passion, and bold expression. Best suited for edgy fashion brands, gaming content, protest-driven campaigns, and high-impact sports promos. If your brand has an attitude and isn’t afraid to make a statement, this music delivers that punch. Want to add fire to your content? Our collection of popular Bollywood blockbusters is 100% copyright-safe and easy to license, so your short-form promotional videos can be bold, loud, and unapologetic.",
    },
    "469a2a08-704b-4339-8b77-cc6314f2d8ac": {
        "name": "Anthemic",
        "description": "Anthemic music is all about capturing that ‘josh’ - grand, powerful soundscapes that make a statement. With soaring vocals, stadium-filling beats, and larger-than-life energy, it's perfect for brand anthem videos, product launch campaigns, patriotic content, and motivational brand storytelling. Whether you’re unveiling a game-changing innovation or rallying a movement, this music creates an unforgettable moment. Looking for a soundtrack that unites and inspires? Our collection of popular Bollywood anthems is copyright-safe and easy to license, so your short-form promotional videos can rise to the occasion.",
    },
    // "afabbc54-6e4a-4e77-ab10-ca63a543b4c8": {
    //     "name": "Badass",
    //     "description": "Powerful and edgy music for rebellious and edgy content.",
    //     "img": ""
    // },
    // "3f674d09-a211-4f0d-8647-a8909e077d8b": {
    //     "name": "Busy",
    //     "description": "Energetic and fast-paced music to match the hustle and bustle of modern life.",
    // },
    "fc6d31ac-ee6e-489c-a7dc-79da156c3247": {
        "name": "Calm",
        "description": "Take a deep breath—calm music is here to slow things down. With soft pianos, airy synths, and gentle acoustic textures, this soundscape is soothing and stress-free. Ideal for meditation apps, wellness brands, spa promotions, mindfulness content, and relaxation-focused advertisements. Whether it’s a guided breathing session, a self-care product, or a peaceful brand message, this music helps set the right tone. Need a soundtrack that brings instant tranquility? License our collection of copyright-safe trendy Bollywood hits so your short-form promotional videos can create an atmosphere of peace and serenity. Let’s exhale and relax.",
    },
    "3fd90f5f-f872-466e-8f97-075ea0635ee4": {
        "name": "Celebratory",
        "description": "Cue the confetti! Celebratory music is all about joy, success, and unforgettable moments! Upbeat rhythms, festive instrumentals, and happy melodies make it a great fit for festive brand campaigns, milestone celebrations, product launches, and wedding content. Whether it’s a New Year’s Eve bash, a festival promo, or a brand hitting a major achievement, this music sets the scene for excitement. Need a track that makes every moment feel like a win? We provide copyright safety for our easy-to-license playlist of trending Bollywood chartbusters so your short-form promotional videos can be full of energy and cheer.",
    },
    // "b9adc674-d482-4900-bb9c-65c57690ba3e": {
    //     "name": "Chill",
    //     "description": "Laid-back and mellow music for relaxation and chill-out sessions.",
    // },
    "51ad375b-d2c4-437e-af1d-c171f6054119": {
        "name": "Dark",
        "description": "Mysterious, moody, and full of depth—dark music brings a sense of intrigue and suspense to any story. Think deep basslines, eerie synths, and haunting melodies that keep audiences on edge. Best suited for thriller trailers, crime documentaries, tech brands, and fashion campaigns that embrace the bold and edgy. If your content thrives in shadows, this music adds that cinematic depth. Want to create an air of mystery? License our collection of copyright-safe trendy Bollywood hits, so your short-form promotional videos can be as thrilling as your brand. Let’s step into the unknown.",
    },
    // "b617ce60-ced1-4f75-853c-76545f8cb163": {
    //     "name": "Depressed",
    //     "description": "Sad and melancholic music, perfect for dramatic scenes, heartbreaks, and emotional content.",
    // },
    "92a2b8ff-5b82-4f0b-98ad-80ab2c012a9f": {
        "name": "Dramatic",
        "description": "Every great story needs drama! With sweeping orchestras, emotional crescendos, and intense builds, dramatic music adds weight and impact to visuals. Ideal for cinematic storytelling, luxury brand campaigns, high-fashion commercials, and heartfelt cause-based messaging. Whether you’re launching a powerful ad campaign or unveiling a high-stakes moment, this music makes it unforgettable. Need to dial up the drama? Our collection of popular Bollywood anthems is 100% copyright-safe and easy to license, so your short-form promotional videos can deliver emotion without a hitch. Let’s set the stage for something epic.",
    },
    "6a011e8c-5048-4448-bce2-5458dadf1421": {
        "name": "Dreamy",
        "description": "Soft, surreal, and beautifully atmospheric—dreamy music transports listeners to another world. Featuring lush synths, soulful tunes, and ethereal melodies, it’s the ideal choice for beauty brands, high-end fashion, storytelling content, and wellness-focused ads. Whether you want to evoke nostalgia, fantasy, or pure serenity, this music sets the mood effortlessly. Looking for a soundtrack that feels like a daydream? We provide copyright safety for our easy-to-license playlist of popular Bollywood blockbusters, so your short-form promotional videos can be as enchanting as your vision. Let’s float away into something beautiful.",
    },
    "9e6482af-cca0-42c8-a9a7-7cc4a634d5a3": {
        "name": "Elegant",
        "description": "Refined, sophisticated, and timeless—elegant music is pure class. With soft strings, delicate piano arrangements, and graceful melodies, it’s the ideal backdrop for luxury brands, premium fashion, fine dining experiences, and high-end product commercials. Whether you’re crafting a polished brand identity or showcasing a premium lifestyle, this music elevates every frame. Need to add a touch of sophistication? Our collection of popular and trendy Bollywood hits is 100% copyright-safe and easy to license, so your short-form promotional videos can be as stylish as your brand.",
    },
    // "bed6f8ce-d5b3-4d09-acd3-a4629ed69592": {
    //     "name": "Emotional",
    //     "description": "Evocative and poignant music, perfect for dramas, emotional scenes, and sentimental ads.",
    // },
    "f39282cf-64be-455d-b17e-6efa6dc0fa01": {
        "name": "Energetic",
        "description": "High-energy, full-throttle, and impossible to ignore—energetic music is all about keeping the momentum going! With fast-paced beats, electric guitars, and dynamic rhythms, this music fuels excitement. Ideal for sports brands, fitness campaigns, gaming content, high-energy product launches, and youth-driven advertisements. Whether you’re showcasing an intense workout, an action-packed game, or a brand that never slows down, this soundtrack ensures your content stays pumped. Need a sound that keeps up with the action? Our collection of trending Bollywood chart-busters is 100% copyright-safe and easy to license, so your short-form promotional videos can stay electrifying.",
    },
    // "6a026083-a5ae-4a1b-8fff-1075cf069373": {
    //     "name": "Epic",
    //     "description": "Grand and sweeping music for epic scenes and trailers.",
    // },
    "7e98f210-b995-46f0-973c-0da8e6713c17": {
        "name": "Ethnic",
        "description": "Rooted in tradition, yet timelessly captivating—ethnic music brings cultural richness to your content. With regional instruments, folk rhythms, and touching storytelling, it’s perfect for heritage brands, cultural festivals, tourism campaigns, handcrafted product promotions, and ethnic wear brands. Whether you’re highlighting a traditional art form, celebrating diversity, or connecting with an audience through authenticity, this music bridges cultures beautifully. Want a sound that honors tradition while feeling fresh? Our collection of popular Bollywood music is 100% copyright-safe and easy to license, so your short-form promotional videos can embrace cultural depth without any concerns. Let’s celebrate heritage through sound!",
    },
    // "4ca08b7e-0c98-4922-a776-576e8a36e331": {
    //     "name": "Exciting",
    //     "description": "Upbeat and energetic music to excite and energize your audience. Suitable for revelation, unboxing and curtain raisers.",
    // },
    // "3c75595e-fb29-4efa-b281-73b64e44abb2": {
    //     "name": "Fantasy",
    //     "description": "Magical and mystical music to transport your audience to otherworldly realms.",
    // },
    "50d100e3-5353-491c-ad52-7f2758bb3dda": {
        "name": "Feel Good",
        "description": "Sunshine in a soundtrack! Feel-good music is all about positivity, energy, and pure happiness—the kind of tunes that instantly lift your mood and put a smile on your face. With upbeat rhythms, catchy melodies, and warm vibes, it’s ideal for lifestyle brands, fun product launches, travel vlogs, food commercials. Whether you're promoting a refreshing new drink, an adventurous experience, or a heartwarming moment, this music creates an atmosphere of joy, excitement, and optimism. The best part? Our collection of feel-good Bollywood hit tracks is 100% copyright-safe and easy to license, so you can add that burst of happiness to your short-form promotional videos—completely worry-free.",
    },
    // "623df712-99f1-4fa9-8636-3cf3a15e335f": {
    //     "name": "Festive",
    //     "description": "Cheerful and celebratory music for festivals and holidays.",
    // },
    "fdb3543d-af3b-4712-8fb8-a38a38f257ae": {
        "name": "Flirty",
        "description": "Playful, cheeky, and irresistibly fun—flirty music is the soundtrack to lighthearted romance and charming moments. With bouncy basslines, jazzy chords, and a hint of sass, this music is great for beauty brands, dating apps, fashion ads, lifestyle promos, and social media campaigns. Whether you're teasing a new product, setting a playful tone, or creating content with a little wink, this soundtrack turns on the charm. Copyright protection? Check. Easy licensing? Double check. Our collection of trendy Bollywood numbers is 100% copyright-safe and easy to license, so your short-form promotional videos can stay fun and flirty.",
    },
    "43d114d0-ae93-48a2-a079-bab015a9be89": {
        "name": "Fun",
        "description": "Boredom? Never heard of it! Fun music is bright, catchy, and bursting with energy. With upbeat melodies, funky grooves, and playful rhythms, this sound is a good match for kids’ brands, quirky social media content, a campaign targeting Gen-Z audiences, party promotions, and fast-paced advertisements. Whether you're launching a viral challenge, a playful product, or an event full of excitement, this music keeps the fun going. Need a track that screams good times? We give easy-to-license trending Bollywood hits and copyright protection for them so your short-form promotional videos can be full of energy without any worries.",
    },
    // "5888d8b9-503a-4034-a8a2-0a7c3072ac55": {
    //     "name": "Funny",
    //     "description": "Humorous and quirky music, perfect for comedy sketches, sitcoms, and funny commercials.",
    // },
    "8da09f93-eaa1-423b-adb7-93749d161e59": {
        "name": "Glamorous",
        "description": "Dripping in luxury and style, glamorous music is all about sophistication and elegance. Featuring smooth jazz, chic beats, and cinematic orchestration, this music is best suited for fashion brands, high-end product commercials, luxury brand campaigns, and premium lifestyle content. Whether you’re showcasing a designer collection, unveiling a sleek car, or promoting a glamorous event, this soundtrack adds pure finesse. Want to elevate your brand’s elegance? Our collection of trendy Bollywood chart-toppers is 100% copyright-safe and easy to license, so your short-form promotional videos can sparkle with style. Let’s make everything effortlessly glamorous!",
    },
    // "e15b4625-c45b-4692-ba86-311bb2aa2c16": {
    //     "name": "Grand",
    //     "description": "Majestic and powerful music for grand and epic occasions.",
    // },
    "f1b0d86f-ffaf-4387-b7e5-914eeada7383": {
        "name": "Groovy",
        "description": "Basslines that make you move, rhythms that stay in your head—groovy music is effortlessly cool! With funk-inspired beats, soulful vibes, and catchy hooks, this genre is ideal for fashion brands, nightlife promotions, retro product launches, dance-related content, and trendy social media campaigns. Whether you're showing off a new sneaker line, hyping up a club event, or adding swagger to your brand, this soundtrack keeps things fresh. Need a sound that gets people nodding along? We provide a collection of copyright-safe and easy-to-license popular tracks, so your short-form promotional videos can groove with confidence.",
    },
    "0ca27d5b-d78a-4ac5-875c-1008756ef158": {
        "name": "Happy",
        "description": "Sunshine in a song—happy music radiates pure joy and positivity! Featuring cheerful melodies, upbeat tempos, and feel-good vibes, it’s the go-to choice for family-friendly brands, kids' content, travel videos, lifestyle commercials, and positive brand storytelling. Whether you're showcasing a fun day out, a heartwarming moment, or a brand that simply spreads happiness, this music brings a smile to every frame. Want to boost the mood instantly? License our collection of popular Bollywood blockbusters copyright-safe popular Bollywood hits, so your short-form promotional videos can spread joy without a second thought. Let’s make every moment brighter!",
    },
    "375c4386-a819-4aa4-bdd1-3c66b0c3ed5c": {
        "name": "Heartwarming",
        "description": "Soft strings, serene tunes, and melodies that melt hearts—heartwarming music is the sound of love, kindness, and nostalgia. It’s best suited for family-focused brands, emotional brand storytelling, festive campaigns, and heartfelt charity appeals. Whether you’re sharing a touching personal journey, showcasing a brand with a purpose, or celebrating togetherness, this music enhances every tender moment. Looking for a soundtrack that connects emotionally? Our collection of popular Bollywood hits is 100% copyright-safe and easy to license, so your short-form promotional videos can tug at heartstrings.",
    },
    // "5747c295-e345-4265-8921-7833577e391f": {
    //     "name": "Hopeful",
    //     "description": "Optimistic and uplifting music, perfect for inspirational content, motivational videos, and feel-good ads.",
    // },
    // "70fc6a0c-a6e6-4da8-95a8-9782bdea9900": {
    //     "name": "Horror",
    //     "description": "Dark and eerie music to create suspense and fear.",
    // },
    "d0d2b2dc-6791-47a1-902e-1b14788757f1": {
        "name": "Inspiring",
        "description": "Rise, shine, and conquer! Inspiring music is all about empowerment, determination, and uplifting energy. Featuring soaring melodies, powerful crescendos, and motivational beats, it’s a must-have for corporate branding, startup success stories, sports motivation, and personal growth campaigns. Whether you're launching a brand that changes lives, telling a comeback story, or motivating an audience to chase their dreams, this music sets the perfect tone. Need a soundtrack that drives ambition? Our collection isLicense our collection of copyright-safe popular Bollywood hits, so your short-form promotional videos can inspire action without limits.",
    },
  //   "9200766a-1c9e-4c61-9d84-1402310790c6": {
  //       "name": "Intense",
  //       "description": "Intense and dramatic music to build tension and excitement.",
  // },
  //   "ed7d43f9-918f-4e20-80dc-e3113c556803": {
  //       "name": "Intimate",
  //       "description": "",
  //   },
  //   "1e0e85bc-19a2-422a-8553-0939f08f78aa": {
  //       "name": "Joyful",
  //       "description": "Upbeat and energetic music, perfect for party scenes, comedies, and upbeat commercials.",
  //   },
    "115a3e41-d2ee-47ae-9fa1-c96c21c20178": {
        "name": "Lazy",
        "description": "Some days are just meant for lounging. Lazy music is all about chilled-out vibes, slow grooves, and relaxed melodies that make everything feel effortless. Whether it’s a laid-back Sunday or a cozy evening, this sound fits idly. Ideal for lifestyle brands, slow-living content, wellness products, spa promotions, and relaxation-themed videos. If your brand is all about taking it easy—whether it’s comfy furniture, self-care, or mindful living—this music sets the perfect mood. Looking for a soundtrack that lets your content breathe? Our collection of trending Bollywood tracks is 100% copyright-safe and easy to license, so your short-form promotional videos can feel as smooth as a lazy afternoon.",
    },
    // "66585de3-83a6-44d4-a651-4f8c0dcffec8": {
    //     "name": "Melancholic",
    //     "description": "Sad and reflective music, perfect for dramas, heartbreaks, and emotional scenes.",
    // },
    "7e9caf70-1cd5-4e82-8ee0-8f4590f2f8bb": {
        "name": "Motivational",
        "description": "It’s time to get up and get moving! Motivational music is packed with uplifting beats, powerful crescendos, and melodies that push you forward. Whether it’s hitting the gym, starting a new journey, or achieving a big goal, this soundtrack fuels the hustle. Ideal for fitness brands, corporate success stories, self-improvement content, and sports motivation. Whether you’re launching a campaign to inspire or highlight personal achievements, this music delivers that extra boost. Need a soundtrack that screams success? We provide copyright safety for our easy-to-license playlist of popular Bollywood tracks so your short-form promotional videos can be as inspiring as your message.",
    },
    "3bbdfc0a-78a8-4efc-8f51-7b30162c755b": {
        "name": "Mysterious",
        "description": "Something is thrilling about the unknown. Mysterious music creates suspense, intrigue, and an air of secrecy with deep basslines, eerie synths, and haunting melodies. Great for thriller trailers, crime documentaries, escape room experiences, and high-end fashion campaigns with a dark twist. Whether you’re building curiosity, teasing a big reveal, or crafting a moody aesthetic, this music keeps audiences hooked. Want a soundtrack that makes people lean in? Our collection is popular Bollywood tunes 100% copyright-safe and easy to license, so your short-form promotional videos can keep the mystery alive.",
    },
    "ad8e3c56-3a40-409d-b1c5-01e9a35a6e23": {
        "name": "Nostalgic",
        "description": "Take a trip down memory lane! Nostalgic music blends warm melodies, classic sounds, and heartfelt emotions to bring back cherished moments. Whether it’s a retro vibe or a sentimental story, this music wraps listeners in familiarity. Ideal for vintage product campaigns, storytelling content, brand legacy videos, and family-focused commercials. Whether you’re celebrating the past, honoring tradition, or adding a retro touch, this soundtrack brings that old-school charm. Looking for a sound that feels like home? License our collection of copyright-safe popular Bollywood hits, so your short-form promotional videos can stir up beautiful memories without worry.",
    },
    // "ad055f48-c801-46a3-9836-616bbc43095e": {
    //     "name": "Peppy",
    //     "description": "Energetic and upbeat music to get you moving.",
    // },
    // "18bcd8ec-4d62-48fb-a95e-66123d3d2f39": {
    //     "name": "Rebellious",
    //     "description": "Edgy and rebellious music, perfect for action movies, sports commercials, and rebellious brands.",
    // },
    "550d8185-9e23-45a9-90bb-e8993b3c9759": {
        "name": "Relaxing",
        "description": "Deep breaths, calm mind, and pure tranquility—relaxing music is designed to melt stress away. With soft synths and soothing rhythms, it’s a great fit for spa promotions, meditation content, wellness brands, and mindful living campaigns. Whether your brand is about self-care, sleep enhancement, or stress relief, this soundtrack sets the perfect tone. Want a music selection that feels like a deep breath? We provide copyright safety for our easy-to-license playlist of trending Bollywood tunes, so your short-form promotional videos can create a peaceful escape for your audience.",
    },
    "ed820c8e-03f5-43d1-bbd8-c96c51b31f88": {
        "name": "Romantic",
        "description": "Love is in the air! Romantic music sets the mood with soft melodies, heartfelt lyrics, and dreamy instrumentals that make every moment feel special. Ideal for wedding videos, dating apps, jewelry brands, romantic getaway promotions, and Valentine’s Day campaigns. Whether you’re capturing a love story, launching a couple-centric brand, or setting a dreamy aesthetic, this music makes hearts flutter. Want a soundtrack that speaks the language of love? Our collection of popular Bollywood anthems is 100% copyright-safe and easy to license, so your short-form promotional videos can capture romance without a hitch.",
    },
    "91365ba2-a58c-4152-a5b3-780ea9f62216": {
        "name": "Sad",
        "description": "Sometimes, emotions run deep. Sad music brings out raw, heartfelt feelings with melancholic melodies, soft strings, and soulful piano compositions. It’s a good fit for emotional storytelling, awareness campaigns, heartfelt brand messages, and cinematic trailers. Whether you're sharing a moving story, bringing attention to a cause, or setting a dramatic tone, this music enhances every tear-jerking moment. Need a soundtrack that resonates emotionally? License our collection of copyright-safe popular Bollywood hits, so your short-form promotional videos can connect on a deeper level.",
    },
    // "d40a1f69-6d17-4f38-bc90-fcab1d780296": {
    //     "name": "Sensual",
    //     "description": "Sultry and seductive music. Ideal for sensual scenes in movies, music videos, and adult content.",
    // },
    // "22a59a52-5591-4d8b-9fc0-a5282e2a642e": {
    //     "name": "Serious",
    //     "description": "Serious and solemn music for formal and professional occasions.",
    // },
    "eaf38e4e-3afc-40bf-9670-3a3e95c2ebd7": {
        "name": "Sexy",
        "description": "Smooth, sultry, and irresistibly cool—sexy music adds a touch of allure and confidence to any scene. With deep basslines, jazzy instrumentals, and seductive melodies, it’s ideal for luxury brands, high-end fashion, perfume commercials, and sleek product promotions. Whether you're setting the mood for a stylish campaign or adding a little intrigue, this music turns up the heat. Want to add some undeniable charm to your content? We provide copyright safety for our easy-to-license playlist of popular Bollywood tracks, so your short-form promotional videos can stay smooth and sophisticated.",
    },
    // "398ee4b5-ba5a-4904-b76b-34e0a912030d": {
    //     "name": "Soft",
    //     "description": "Gentle and soothing music for delicate and sensitive moments.",
    // },
    "dec28c89-f48a-4c39-9582-3eb3e822a683": {
        "name": "Soulful",
        "description": "Rich, emotional, and deeply expressive—soulful music is all about heart and depth. With smooth vocals, groovy rhythms, and warm melodies, it’s a great fit for lifestyle brands, storytelling content, luxury experiences, and heartfelt commercials. Whether you’re highlighting human connections, capturing deep emotions, or adding a touch of class, this music brings richness to every moment. Want a soundtrack that resonates with pure soul? Our collection of trendy Bollywood melodies is 100% copyright-safe and easy to license, so your short-form promotional videos can be as heartfelt as your message.",
    },
    "7d58aafd-8d01-4625-b513-4b291ff4495a": {
        "name": "Spiritual",
        "description": "Sacred, uplifting, and full of devotion—spiritual music brings peace, faith, and transcendence. Featuring soft chants, ethereal vocals, and meditative instrumentals, it’s best suited for devotional content, yoga and meditation apps, mindful living brands, and faith-based campaigns. Whether you're sharing messages of hope, promoting inner peace, or connecting with deeper beliefs, this music elevates the experience. Looking for a sound that touches the soul? License our collection of copyright-safe popular Bollywood hits so your short-form promotional videos can spread light and harmony without a second thought.",
    },
    // "03ce178f-e648-4c58-a840-af199ab13b13": {
    //     "name": "Suspense",
    //     "description": "Tense and mysterious music to build suspense and anticipation.",
    // },
    // "6c29befb-abce-4b79-8132-d23345a7bee3": {
    //     "name": "Sweet",
    //     "description": "Soft, romantic, and sentimental music. Perfect for love songs, romantic comedies, and heartwarming ads.",
    // },
    // "eaf56e56-4e35-4db9-8bf1-a34be195bbf1": {
    //     "name": "Thoughtful",
    //     "description": "Reflective and contemplative music for introspective moments.",
    // },
    "dc0ba229-e598-495f-bf6e-9fb209ebe097": {
        "name": "Thriller",
        "description": "Edge-of-your-seat tension, eerie undertones, and heart-racing suspense—thriller music keeps audiences hooked. With dark synths, intense beats, and a cinematic build-up, this music is a must-have for horror trailers, suspenseful ads, crime documentaries, and dramatic brand storytelling. Whether you’re creating a mystery, teasing an upcoming project, or diving into high-stakes storytelling, this soundtrack keeps the tension alive. Want a score that keeps viewers on edge? We provide copyright safety for our easy-to-license playlist of Bollywood so your short-form promotional videos can stay thrilling without legal worries. Let’s set the scene for suspense!",
    },
    // "e5f21bae-1943-4a6e-b36f-e15a6e2df5d4": {
    //     "name": "Upbeat",
    //     "description": "Energetic and positive music to uplift and inspire.",
    // }
};

export default moods;