// import axios from "axios";
// import config from "../utils/config";
import { getSearchResultFaceted } from "./SearchService";
import RandomDefaultImage from "../utils/RandomDefaultImage";
import getDirectLink from "../utils/AudioUrlFormated";

const similarTracks = async (objSearch, searchQuery = "") => {
  let searchData = await getSearchResultFaceted(searchQuery, objSearch, 1);

  searchData = searchData.slice(0, 5);

  const allTracks = searchData.map((track) => {
    return {
      image: track.image_url || RandomDefaultImage("track"),
      title: track.name,
      description: JSON.parse(track.tracks_artist_role || "") // Ensure valid JSON
        // .map((role) => role.artist?.name || "")
        // .join(", ");
        .filter((item) => item.role === "primary")
        .map((item) => item.artist.name)
        .join(", "),
      buttonText: "Explore",
      discountPercent: "25",
      costPrice: "",
      sellingPrice: "",
      name: track.name_slug,
      trackCode: track.track_code,
      trackUrl: track.mp3_link || getDirectLink(track.link),
      waveJson: track.waveform_link,
    };
  });

  return allTracks;
  // console.log(allTracks, "All Tracks");
};

export { similarTracks };
