import React, { useState, useEffect, useContext } from "react";
import "../styles/Header.css";
import SearchBar from "../components/SearchBar/SearchBar";
import config from "../utils/config";
import CustomButton from "../components/CustomButton/CustomButton";
import { MdMenu } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
import { LuSearch } from "react-icons/lu";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchTrackFilters } from "../utils/redux/trackFiltersSlice";
import Login from "./Login";
import { useAuth } from "../utils/AuthContext";
import { useNavigate } from "react-router-dom";
import Cart from "./Cart";
import { selectItemCount } from "../utils/redux/cartSlice";
import CustomImg from "../components/CustomImg/CustomImg";
import { FaRegCircleUser } from "react-icons/fa6";
import { logout } from "../utils/redux/authSlice";
import BgImageDiv from "../components/BgImageDiv/BgImageDiv";
import RequestSong from "./RequestSong";
import { ToastContext } from "../utils/ToastContext";

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // STATE TO CHECK THE MOBILE VIEW ACTIVE STATUS
  const [isSearchActive, setIsSearchActive] = useState(false); //  STATE TO CHECK THE SEARCH BAR ACTIVE STATUS
  const [activeTab, setActiveTab] = useState("usecase"); // Default tab is "mood"
  const dispatch = useDispatch(); // action to refresh the filters mood, genre, usecase
  const [isModalOpen, setIsModalOpen] = useState(false); // toggle login modal
  const [isCartOpen, setIsCartOpen] = useState(false); // toggle cart modal
  const totalItemCount = useSelector(selectItemCount); // Get the item count from Redux store
  const [activeMenu, setActiveMenu] = useState(null); // menu item for mobile
  const [subMenu, setSubMenu] = useState(null); // sub menu items for mobile
  const [isRequestOpen, setIsRequestOpen] = useState(false); // toggle cart modal
  const { showToast } = useContext(ToastContext); // context hook to display the toast meassage
  const [selectedOccasionCategory, setSelectedOccasionCategory] =
    useState(null); // state to track in mobile menu

  const { genres, useCases, moods, status } = useSelector(
    (state) => state.trackFilters
  );
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // toggle menu for mobile headers
  const toggleMenu = (menu) => {
    setActiveMenu(activeMenu === menu ? null : menu);
    setSubMenu(null);
  };

  // toggle menu sub header for mobile
  const openSubMenu = (menu) => {
    setSubMenu(menu);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      setIsModalOpen(true);
    }
  }, [isAuthenticated]);

  const navigate = useNavigate();

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTrackFilters());
    }
  }, [status, dispatch]);

  const categories = {
    moods: moods.map((filter) => ({
      title: filter.label,
      slug: filter.slug,
      id: filter.value,
    })),
    genre: genres.map((filter) => ({
      title: filter.label,
      slug: filter.slug,
      id: filter.value,
    })),
    usecase: useCases.map((filter) => ({
      title: filter.label,
      slug: filter.slug,
      id: filter.value,
    })),
  };

  // FUNCTION TO TOGGLE THE MOBILE VIEW
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // FUNCTION TO TOGGLE THE SEARCH BAR
  const toggleSearch = () => {
    setIsSearchActive(!isSearchActive);
  };

  const handleLogout = () => {
    localStorage.clear(); // temp purpose
    dispatch(logout()); // This will change the auth state to unauthenticated
    navigate("/"); // Redirect to the home page
  };

  // mobile menu options
  const categoriesMobile = ["Use Case", "Mood", "Genre"];
  const supportMobile = [
    { name: "How to Use", link: "/how-to-use" },
    { name: "About Us", link: "/about" },
    { name: "Blog", link: "https://blog.hooprsmash.com" },
    { name: "FAQs", link: "/faqs" },
    { name: "Contact Us", link: "/contact-us" },
    { name: "Sales Enquiry", link: "/contact-sales" },
    { name: "Claims Support", link: "#" },
    // { name: "Request A Song", link: "/request-a-song" },
  ];

  // mobile menu handle navigation fucntion
  const handleMenuClick = (link, external = false) => {
    if (external) {
      window.open(link, "_blank"); // Opens external link in a new tab
    } else {
      setActiveMenu(null);
      setSubMenu(null);
      setSelectedOccasionCategory(null);
      navigate(link); // React Router navigation for internal links
    }
    toggleMobileMenu(); // Close the menu
  };

  const upcomingEvents = {
    eventsList: [
      {
        title: "Valentine's Day",
        imgUrl: `${config.s3imgUrl}web/header/smash-hits/feb-14.png`,
      },
      {
        title: "Maha Shivratri",
        imgUrl: `${config.s3imgUrl}web/header/smash-hits/feb-26.png`,
      },
      {
        title: "Holi",
        imgUrl: `${config.s3imgUrl}web/header/smash-hits/mar-14.png`,
      },
      {
        title: "Ramadan",
        imgUrl: `${config.s3imgUrl}web/header/smash-hits/mar-31.png`,
      },
      {
        title: "Ram Navami",
        imgUrl: `${config.s3imgUrl}web/header/smash-hits/apr-06.png`,
      },
      {
        title: "Mother's Day",
        imgUrl: `${config.s3imgUrl}web/header/smash-hits/may-11.png`,
      },
    ],
    bannerList: [
      {
        title: "New on Smash",
        imgUrl: `${config.s3imgUrl}web/header/smash-hits/new.png`,
        url: "/playlists/new-on-smash/106",
      },
      {
        title: "Trending on Social Media",
        imgUrl: `${config.s3imgUrl}web/header/smash-hits/trending.png`,
        url: "/playlists/trending-on-social-media/105",
      },
      {
        title: "Popular Bollywood",
        imgUrl: `${config.s3imgUrl}web/header/smash-hits/popular.png`,
        url: "/playlists/popular-bollywood/14",
      },
      {
        title: "Independent Hits",
        imgUrl: `${config.s3imgUrl}web/header/smash-hits/indie.png`,
        url: "/playlists/independent-hits/104",
      },
    ],
  };

  const packsMenuItems = {
    s1: [
      {
        title: "Big Bang Chartbusters",
        link: "/playlists/pack-big-bang-chartbusters/15",
      },
      {
        title: "Boss Brands",
        link: "/playlists/pack-boss-brands/18",
      },
      {
        title: "Capture the Moment",
        link: "playlists/pack-capture-the-moment/19",
      },
      {
        title: "Childhood Innocence",
        link: "/playlists/pack-childhood-innocence/20",
      },
      {
        title: "Classical Creations",
        link: "playlists/pack-classical-creations/23",
      },
      {
        title: "Desi Devotion",
        link: "/playlists/pack-desi-devotion/16",
      },
      {
        title: "Festivals of India (Jan - Mar)",
        link: "/playlists/pack-festivals-of-india-(jan-mar)/27",
      },
      {
        title: "Festivals of India (Apr - Jun)",
        link: "/playlists/pack-festivals-of-india-(apr-jun)/110",
      },
    ],
    s2: [
      {
        title: "Festivals of India (Jul - Sep)",
        link: "/playlists/pack-festivals-of-india-(jul-sept)/111",
      },
      {
        title: "Festivals of India (Oct- Dec)",
        link: "/playlists/pack-festivals-of-india-(oct-dec)/112",
      },
      {
        title: "Fusion Mantra",
        link: "/playlists/pack-fusion-mantra/24",
      },
      {
        title: "Happily Ever After",
        link: "/playlists/pack-happily-ever-after/13",
      },
      {
        title: "Heroes We Love",
        link: "/playlists/pack-heroes-we-love/21",
      },
      {
        title: "I Love My India",
        link: "/playlists/pack-i-love-my-india/10",
      },
      {
        title: "Love is in the Air",
        link: "/playlists/pack-love-is-in-the-air/11",
      },
      {
        title: "Pulse Racers",
        link: "/playlists/pack-pulse-racers/17",
      },
    ],
    s3: [
      {
        title: "Reels Mania",
        link: "/playlists/pack-reels-mania/6",
      },
      {
        title: "The Yogi's Playlist",
        link: "/playlists/pack-the-yogi's-playlist/26",
      },
      {
        title: "Traveller's Tunes",
        link: "/playlists/pack-traveller's-tunes/25",
      },
      {
        title: "Trendsetters",
        link: "/playlists/pack-trendsetters/12",
      },
      {
        title: "Where's The Party?",
        link: "/playlists/pack-where's-the-party/7",
      },
      {
        title: "Women Who Rule",
        link: "/playlists/pack-women-who-rule/22",
      },
      {
        title: "Year End Celebrations",
        link: "/playlists/pack-year-end-celebrations/8",
      },
    ],
  };

  const filterTracksItems = {
    budget: [
      {
        title: "Tracks Under ₹4,999",
        link: "",
      },
      {
        title: "Tracks Between ₹5,000 - ₹24,999",
        link: "",
      },
      {
        title: "Tracks Between ₹25,000 - ₹44,999",
        link: "",
      },
      {
        title: "Tracks Between ₹45,000 - ₹59,999",
        link: "",
      },
      {
        title: "Tracks Above  ₹60,000",
        link: "",
      },
    ],
    byType: [
      {
        title: "Vocal",
        link: "",
        img: "https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/home/vocal.png",
      },
      {
        title: "Instrumental",
        link: "",
        img: "https://hoopr-smash-images.s3.ap-south-1.amazonaws.com/web/home/instrumental.png",
      },
    ],
    byLanguage: {
      s1: [
        {
          title: "Assamese",
          link: "",
        },
        {
          title: "Bengali",
          link: "",
        },
        {
          title: "Bhojpuri",
          link: "",
        },
        {
          title: "English",
          link: "",
        },
        {
          title: "Gujarati",
          link: "",
        },
        {
          title: "Haryanvi",
          link: "",
        },
        {
          title: "Hindi",
          link: "",
        },
      ],
      s2: [
        {
          title: "Kannada",
          link: "",
        },
        {
          title: "Malayalam",
          link: "",
        },
        {
          title: "Marathi",
          link: "",
        },
        {
          title: "Punjabi",
          link: "",
        },
        {
          title: "Rajasthani",
          link: "",
        },
        {
          title: "Tamil",
          link: "",
        },
        {
          title: "Telugu",
          link: "",
        },
      ],
    },
  };

  const occasionsItems = {
    celebrations: [
      {
        title: "Anniversary",
        link: "/search?q=anniversary",
      },
      // {
      //   title: 'Baby Shower',
      //   link: '/search?q=baby%20shower'
      // },
      {
        title: "Birthday",
        link: "/search?q=birthday",
      },
      {
        title: "Engagement",
        link: "/search?q=engagement",
      },
      // {
      //   title: "House Warming",
      //   link: "/search?q=house%20warming",
      // },
      {
        title: "Party",
        link: "/search?q=party",
      },
      {
        title: "Sangeet",
        link: "/search?q=sangeet",
      },
      {
        title: "Wedding",
        link: "/search?q=wedding",
      },
    ],
    sports: [
      {
        title: "IPL",
        link: "/search?q=birthday",
      },
      // {
      //   title: "Women's T20 World Cup",
      //   link: '/search?q=birthday'
      // }
    ],
    january: [
      {
        title: "Lohri",
        link: "/search?q=lohri",
      },
      {
        title: "Pongal",
        link: "/search?q=pongal",
      },
      // {
      //   title: "Makar Sankranti",
      //   link: "/search?q=makar sankranti",
      // },
      {
        title: "Republic Day",
        link: "/search?q=republic day",
      },
    ],
    february: [
      // {
      //   title: "Vasant Panchami",
      //   link: "/search?q=vasant panchami",
      // },
      {
        title: "Valentine's Day",
        link: "/search?q=valentines day",
      },
      {
        title: "Maha Shivratri",
        link: "/search?q=maha shivratri",
      },
    ],
    march: [
      {
        title: "Ramadan",
        link: "/search?q=eid",
      },
      {
        title: "Holi",
        link: "/search?q=holi",
      },
      // {
      //   title: "Ugadi",
      //   link: "/search?q=ugadi",
      // },
      // {
      //   title: "Gudi Padwa",
      //   link: "/search?q=gudi padwa",
      // },
      // {
      //   title: "Eid-ul-Fitr",
      //   link: "/search?q=eid-ul-fitr",
      // },
    ],
    april: [
      {
        title: "Ram Navami",
        link: "/search?q=ram navami",
      },
      // {
      //   title: "Mahavir Jayanti",
      //   link: "/search?q=mahavir jayanti",
      // },
      {
        title: "Vaisakhi",
        link: "/search?q=vaisakhi",
      },
      // {
      //   title: "Ambedkar Jayanti",
      //   link: "/search?q=ambedkar jayanti",
      // },
      // {
      //   title: "Bahag Bihu",
      //   link: "/search?q=bahag bihu",
      // },
      // {
      //   title: "Good Friday",
      //   link: "/search?q=good friday",
      // },
      // {
      //   title: "Easter",
      //   link: "/search?q=easter",
      // },
    ],
    may: [
      // {
      //   title: "International Labour Day",
      //   link: "/search?q=international labour day",
      // },
      {
        title: "Mother's Day",
        link: "/search?q=mothers day",
      },
    ],
    june: [
      {
        title: "Buddha Purnima",
        link: "/search?q=rambuddha purnimaazan",
      },
      {
        title: "Bakri Eid",
        link: "/search?q=eid",
      },
      {
        title: "Father's Day",
        link: "/search?q=fathers day",
      },
    ],
    july: [
      {
        title: "Guru Purnima",
        link: "/search?q=guru purnima",
      },
    ],
    august: [
      {
        title: "Friendship Day",
        link: "/search?q=friendship day",
      },
      {
        title: "Raksha Bandhan",
        link: "/search?q=raksha bandhan",
      },
      {
        title: "Independence Day",
        link: "/search?q=independence day",
      },
      {
        title: "Janmashtami",
        link: "/search?q=janmashtami",
      },
      // {
      //   title: "Parsi New Year",
      //   link: "/search?q=parsi new year",
      // },
      {
        title: "Ganesh Puja",
        link: "/search?q=ganesh puja",
      },
    ],
    september: [
      {
        title: "Teacher's Day",
        link: "/search?q=teachers day",
      },
      // {
      //   title: "Eid-e-Milad",
      //   link: "/search?q=eid-e-milad",
      // },
      // {
      //   title: "Onam",
      //   link: "/search?q=onam",
      // },
      {
        title: "Durga Puja",
        link: "/search?q=durga puja",
      },
      {
        title: "Navaratri",
        link: "/search?q=navaratri",
      },
    ],
    october: [
      // {
      //   title: "World Smile Day",
      //   link: "/search?q=world smile day",
      // },
      // {
      //   title: "World Elderly Day",
      //   link: "/search?q=world elderly day",
      // },
      {
        title: "Gandhi Jayanti",
        link: "/search?q=gandhi jayanti",
      },
      {
        title: "Dussehra",
        link: "/search?q=dussehra",
      },
      {
        title: "Karva Chauth",
        link: "/search?q=karva chauth",
      },
      // {
      //   title: "Teachers' Day",
      //   link: "/search?q=world teachers day",
      // },
      {
        title: "Mental Health Day",
        link: "/search?q=world mental health day",
      },
      // {
      //   title: "International Day of the Girl Child",
      //   link: "/search?q=international day of the girl child",
      // },
      // {
      //   title: "World Food Day",
      //   link: "/search?q=world food day",
      // },
      {
        title: "Diwali",
        link: "/search?q=diwali",
      },
      // {
      //   title: "Halloween",
      //   link: "/search?q=halloween",
      // },
    ],
    november: [
      // {
      //   title: "Govardhan Puja",
      //   link: "/search?q=govardhan puja",
      // },
      // {
      //   title: "Bhai Dooj",
      //   link: "/search?q=bhai dooj",
      // },
      // {
      //   title: "Chhath Puja",
      //   link: "/search?q=chhath puja",
      // },
      {
        title: "Children's Day",
        link: "/search?q=childrens day",
      },
      // {
      //   title: "International Students Day",
      //   link: "/search?q=international students day",
      // },
      // {
      //   title: "International Men's Day",
      //   link: "/search?q=international mens day",
      // },
      {
        title: "Thanksgiving",
        link: "/search?q=thanksgiving day",
      },
    ],
    december: [
      // {
      //   title: "AIDS Awareness Day",
      //   link: "/search?q=world aids awareness day",
      // },
      // {
      //   title: "Civil Aviation Day",
      //   link: "/search?q=civil aviation day",
      // },
      // {
      //   title: "Human Rights Day",
      //   link: "/search?q=human rights day",
      // },
      {
        title: "Christmas",
        link: "/search?q=christmas",
      },
      {
        title: "New Year's Eve",
        link: "/search?q=new years eve",
      },
    ],
  };

  return (
    <div className="header-container">
      {/* main header section */}
      <div className="header-main-section">
        {!isMobileMenuOpen && (
          <div className="header-logo-tabs">
            {/* Mobile menu icon and logo */}
            <div className="mobile-menu-logo">
              <MdMenu
                color="#434343"
                size={28}
                className="menu-icon"
                onClick={toggleMobileMenu}
              />
              <Link to="/">
                <img
                  src={config.s3imgUrl + "web/logos/smash.png"}
                  // src={config.s3imgUrl + "web/logos/smash-xmas.png"}
                  alt="logo"
                  className="header-logo"
                />
              </Link>
            </div>

            {/* Mobile cart and search icons */}
            <div className="mobile-icons">
              <img
                src={config.s3imgUrl + "icons/carticon.png"}
                alt="cart"
                className="cart-icon"
              />
              <LuSearch
                size={24}
                color={isSearchActive ? "var(--button-primary)" : "#434343"} // Change color if search is active
                onClick={toggleSearch}
                className="search-icon"
              />
            </div>

            {/* Header tabs for desktop */}
            <div className="header-tabs">
              {/* smashits */}
              <div className="header-tab-span">
                <span>SmashHits</span>
                {/* hover div of SmashHits */}
                <div className="hover-content">
                  {/* eventsList */}
                  <div className="smashits-upcoming">
                    <p>Upcoming Events</p>
                    {/* <p>Trending Songs</p> */}
                    <div>
                      {upcomingEvents.eventsList.map((event, index) => (
                        <Link
                          to={`/search?searchKeyword=${encodeURIComponent(
                            event.title
                          )}`}
                        >
                          <div
                            key={index}
                            className="smashits-upcoming-content"
                          >
                            {/* <img
                            className="smashits-upcoming-image"
                            src={event.imgUrl}
                            alt={event.title}
                          /> */}
                            <CustomImg
                              type="square"
                              id={event.title}
                              alt={event.title}
                              imgClass="smashits-upcoming-image"
                              src={event.imgUrl}
                            />
                            <p>{event.title}</p>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                  {/* bannerList */}
                  <div className="smashits-banners-container">
                    {upcomingEvents.bannerList.map((banner, index) => (
                      <Link to={banner.url}>
                        <BgImageDiv
                          type="cat"
                          id={index}
                          divClass="smashits-banners"
                          src={banner.imgUrl}
                        />
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              {/* categories */}
              <div className="header-tab-span">
                <span>Categories</span>
                <div className="hover-content cate-width">
                  <div className="categories-subheader-container">
                    {/* Header Tabs */}
                    <div className="categories-subheader-heading">
                      <Link to={"/categories"}>
                        <span
                          onMouseOver={() => setActiveTab("usecase")}
                          style={{
                            color:
                              activeTab === "usecase"
                                ? "var(--button-primary)"
                                : "",
                          }}
                        >
                          Use Cases
                        </span>
                      </Link>
                      <Link to={"/moods"}>
                        <span
                          onMouseOver={() => setActiveTab("mood")}
                          style={{
                            color:
                              activeTab === "mood"
                                ? "var(--button-primary)"
                                : "",
                          }}
                        >
                          Moods
                        </span>
                      </Link>

                      <Link to={"/genres"}>
                        <span
                          onMouseOver={() => setActiveTab("genre")}
                          style={{
                            color:
                              activeTab === "genre"
                                ? "var(--button-primary)"
                                : "",
                          }}
                        >
                          Genre
                        </span>
                      </Link>
                    </div>

                    {/* Content Sections */}
                    <div className="categories-subheader-items">
                      {/* Moods Section */}
                      {/* Mapping categories.moods to dynamically display content */}

                      {categories.moods.map(
                        (mood, index) =>
                          activeTab === "mood" && (
                            <div key={index} className="cs-item-list-mood">
                              <Link
                                key={index}
                                to={`/moods/${encodeURIComponent(mood.slug)}/${
                                  mood.id
                                }`}
                              >
                                <p>{mood.title}</p>
                              </Link>
                            </div>
                          )
                      )}

                      {/* Genre Section */}

                      {categories.genre.map(
                        (genre, index) =>
                          activeTab === "genre" && (
                            <div key={index} className="cs-item-list-mood">
                              <Link
                                key={index}
                                to={`/genres/${encodeURIComponent(
                                  genre.slug
                                )}/${genre.id}`}
                              >
                                <p>{genre.title}</p>
                              </Link>
                            </div>
                          )
                      )}
                      {/* Use Case Section */}
                      {categories.usecase.map(
                        (usecase, index) =>
                          activeTab === "usecase" && (
                            <div key={index} className="cs-item-list-mood">
                              <Link
                                key={index}
                                to={`/categories/${encodeURIComponent(
                                  usecase.slug
                                )}/${usecase.id}`}
                              >
                                <p>{usecase.title}</p>
                              </Link>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* occasions */}
              <div className="header-tab-span">
                <span>Occasions</span>
                <div className="hover-content occasion-width">
                  <div className="occasion-subheader-container">
                    {Object.entries(occasionsItems).map(
                      ([month, occasions]) => (
                        <div key={month} className="occasion-list">
                          <p>
                            {month.charAt(0).toUpperCase() + month.slice(1)}
                          </p>
                          {occasions.map((occasion, index) => (
                            <Link
                              style={{ margin: 0, padding: 0 }}
                              to={`/search?searchKeyword=${encodeURIComponent(
                                occasion.title
                              )}`}
                            >
                              <span key={index}>{occasion.title}</span>
                            </Link>
                          ))}
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              {/* packs */}
              {/* <div className="header-tab-span">
              <Link to="/packs">
                <span>Packs</span>
              </Link>
              <div className="hover-content hc-packs">
                <div>
                  <p className="smashits-subheader-p">A-Z</p>
                  <div className="pack-sub-header-container">
                    <div className="pack-sub-header"> */}
              {/* packsMenuItems s1 */}
              {/* {packsMenuItems.s1.map((item, index) => (
                        <Link to={item.link}>
                          <p key={index} className="pack-sub-header-p">
                            {item.title}
                          </p>
                        </Link>
                      ))}
                    </div>
                    <div className="pack-sub-header"> */}
              {/* packsMenuItems s2 */}
              {/* {packsMenuItems.s2.map((item, index) => (
                        <Link to={item.link}>
                          <p key={index} className="pack-sub-header-p">
                            {item.title}
                          </p>
                        </Link>
                      ))}
                    </div>
                    <div className="pack-sub-header"> */}
              {/* packsMenuItems s3 */}
              {/* {packsMenuItems.s3.map((item, index) => (
                        <Link to={item.link}>
                          <p key={index} className="pack-sub-header-p">
                            {item.title}
                          </p>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
              {/* filter tracks */}
              {/* <div className="header-tab-span">
              <span>Filter Tracks</span>
              <div className="hover-content ft-track-width">
                <div className="ft-subheader"> */}
              {/* track by budget */}
              {/* <div className="ft-by-budget">
                    <p>By Budget</p>
                    <div className="ft-by-budget-list">
                      {filterTracksItems.budget.map((item, index) => (
                        <div key={index} className="ft-by-budget-list-item">
                          <span>
                            <a href={item.link}>{item.title}</a>
                          </span>
                        </div>
                      ))}
                    </div>
                  </div> */}
              {/* track by type */}
              {/* <div className="ft-by-type">
                    <p>By Type</p>
                    <div className="ft-by-type-list">
                      {filterTracksItems.byType.map((item, index) => (
                        <div className="ft-type-list-wrapper">
                          <div key={index} className="ft-by-type-list-item">
                            <img src={item.img} alt={item.title} />
                          </div>
                          <p style={{ margin: 0 }}>{item.title}</p>
                        </div>
                      ))}
                    </div>
                  </div> */}
              {/* track by language */}
              {/* <div className="ft-by-language">
                    <p>By Language</p>
                    <div className="ft-by-language-list-container">
                      <div className="ft-by-language-list">
                        {filterTracksItems.byLanguage.s1.map((item, index) => (
                          <Link
                            style={{ margin: 0, padding: 0 }}
                            to={`/search?searchKeyword=${encodeURIComponent(
                              item.title
                            )}`}
                          >
                            <div key={index}>
                              <p>{item.title}</p>
                            </div>
                          </Link>
                        ))}
                      </div>
                      <div className="ft-by-language-list">
                        {filterTracksItems.byLanguage.s2.map((item, index) => (
                          <Link
                            style={{ margin: 0, padding: 0 }}
                            to={`/search?searchKeyword=${encodeURIComponent(
                              item.title
                            )}`}
                          >
                            <div key={`s2-${index}`}>
                              <p>{item.title}</p>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div> */}
              {/* </div>
              </div>
            </div> */}
              {/* Explore More */}
              <div className="header-tab-span">
                <span>Explore</span>
                <div className="hover-content explore-width">
                  <div className="ft-subheader">
                    <div>
                      <div className="explore-container">
                        <div className="explore-list">
                          <div className="explore-wrapper">
                            <div>
                              <Link to={"/tracks"}>
                                <div className="explore-list-item">
                                  <img
                                    src={`${config.s3imgUrl}web/header/explore/track.png`}
                                    alt="Explore Tracks"
                                  />
                                </div>
                                <p style={{ margin: 0 }}>Tracks</p>
                              </Link>
                            </div>
                            <div>
                              <Link to={"/packs"}>
                                <div className="explore-list-item">
                                  <img
                                    src={`${config.s3imgUrl}web/header/explore/pack.png`}
                                    alt="Explore Packs"
                                  />
                                </div>
                                <p style={{ margin: 0 }}>Combos & Packs</p>
                              </Link>
                            </div>
                          </div>
                          <div className="explore-wrapper">
                            <div>
                              <Link to={"/playlists"}>
                                <div className="explore-list-item">
                                  <img
                                    src={`${config.s3imgUrl}web/header/explore/playlist.png`}
                                    alt="Explore Playlists"
                                  />
                                </div>
                                <p style={{ margin: 0 }}>Playlists</p>
                              </Link>
                            </div>
                            <div>
                              <Link to={"/languages"}>
                                <div className="explore-list-item">
                                  <img
                                    src={`${config.s3imgUrl}web/header/explore/language.png`}
                                    alt="Explore Languages"
                                  />
                                </div>
                                <p style={{ margin: 0 }}>Languages</p>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Link to={`/playlists/dance-numbers/31`}> */}
              {/* <Link to={`/foryou`}>
              <div className="header-tab-span">
                <span>For You</span>
              </div>
            </Link> */}
              {/* search bar */}
              <div style={{ marginLeft: 25 }}>
                <SearchBar
                  placeholder="Search on smash"
                  iconPosition="end"
                  width="280px"
                  height="44px"
                />
              </div>
            </div>
          </div>
        )}

        {/* login and cart section */}
        <div className="header-login-cart">
          <div className="header-tab-span">
            <span>Support</span>
            <div className="hover-content support-width">
              <div className="ft-subheader">
                <div>
                  <div className="explore-container">
                    <div className="support-list">
                      <div className="support-wrapper">
                        <Link to={"/how-to-use"}>
                          <p style={{ margin: 0 }}>How to Use</p>
                        </Link>
                        <Link to={"/about"}>
                          <p style={{ margin: 0 }}>About Us</p>
                        </Link>
                        <a href="https://blog.hooprsmash.com" target="_blank">
                          <p style={{ margin: 0 }}>Blog</p>
                        </a>
                        <Link to={"/faqs"}>
                          <p style={{ margin: 0 }}>FAQs</p>
                        </Link>
                        <Link to={"/contact-us"}>
                          <p style={{ margin: 0 }}>Contact Us</p>
                        </Link>
                        <Link to={"/contact-sales"}>
                          <p style={{ margin: 0 }}>Sales Enquiry</p>
                        </Link>
                        <Link to={"#"}>
                          <p style={{ margin: 0 }}>Claims Support</p>
                        </Link>
                        <a href="https://forms.gle/Trec9poXtsNpHnJJ8" target="_blank">
                        <p style={{ margin: 0 }}>Call for Artists</p>
                        </a>
                        <p
                          onClick={() => setIsRequestOpen(true)}
                          style={{ margin: 0 }}
                        >
                          Request a Song
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            <a href="https://forms.gle/Trec9poXtsNpHnJJ8" target="_blank">
              <CustomButton
                text="Call for Artists"
                borderRadius="30px"
                height="39px"
                borderColor="none"
                backgroundColor="#F7F7F7"
                width="172px"
                fontSize="16px"
                fontWeight="550"
                fontFamily="Raleway"
                textColor="var(--button-primary)"
              />
            </a>
          </div> */}
          {isAuthenticated ? (
            <div className="user-dropdown-container">
              <FaRegCircleUser className="user-icon" size={24} />
              <div className="user-dropdown">
                <ul>
                  <Link to={`/user`}>
                    <li>My Account</li>
                  </Link>
                  <li onClick={handleLogout}>Logout</li>
                </ul>
              </div>
            </div>
          ) : (
            <div>
              <CustomButton
                text="Log In"
                borderRadius="30px"
                height="39px"
                borderColor="none"
                backgroundColor="var(--button-primary)"
                width="172px"
                fontSize="14px"
                fontWeight="550"
                fontFamily="inter"
                onClick={() => setIsModalOpen(true)}
              />
            </div>
          )}
          <div onClick={() => setIsCartOpen(true)} className="header-cart">
            <div className="cart-icon-container">
              <img
                src={config.s3imgUrl + "icons/carticon.png"}
                alt="logo"
                className="cart-icon"
              />
              <div className="cartCountHeader">{totalItemCount}</div>
            </div>
          </div>
        </div>
        <Login isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        <Cart isOpen={isCartOpen} onClose={() => setIsCartOpen(false)} />
      </div>

      {/* Conditional SearchBar for mobile view */}
      {isSearchActive && (
        <div className="mobile-search-bar">
          <SearchBar placeholder="Search on smash" iconPosition="start" />
        </div>
      )}

      {/* Mobile sidebar menu */}
      {isMobileMenuOpen && (
        <div className="mobile-menu-overlay">
          <div className="mobile-menu-content">
            <div className="close-icon">
              <IoCloseCircleOutline
                size={24}
                color="#434343"
                onClick={toggleMobileMenu}
              />
            </div>
            {!subMenu ? (
              <>
                {/* categories options */}
                <span onClick={() => toggleMenu("categories")}>Categories</span>
                {activeMenu === "categories" && (
                  <div className="mobilehl2">
                    {categoriesMobile.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          if (item === "Mood") openSubMenu("mood");
                          if (item === "Use Case") openSubMenu("usecase");
                          if (item === "Genre") openSubMenu("genres");
                        }}
                      >
                        <p>{item}</p>
                      </div>
                    ))}
                  </div>
                )}

                {/* Occasions */}
                <span onClick={() => toggleMenu("occasions")}>Occasions</span>
                {activeMenu === "occasions" && (
                  <div className="mobilehl2">
                    {Object.keys(occasionsItems).map((category, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          openSubMenu("occasions");
                          setSelectedOccasionCategory(category);
                        }}
                      >
                        <p>
                          {category.charAt(0).toUpperCase() + category.slice(1)}
                        </p>
                      </div>
                    ))}
                  </div>
                )}

                {/* support options */}
                <span onClick={() => toggleMenu("support")}>Support</span>
                {activeMenu === "support" && (
                  <div className="mobilehl2">
                    {supportMobile.map((item, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          handleMenuClick(
                            item.link,
                            item.link.startsWith("http")
                          )
                        }
                      >
                        {item.link ? (
                          item.link.startsWith("http") ? (
                            <p>{item.name}</p> // External links (e.g., Blog)
                          ) : (
                            <Link to={item.link}>
                              <p>{item.name}</p>
                            </Link>
                          )
                        ) : (
                          <p>{item.name}</p>
                        )}
                      </div>
                    ))}
                  </div>
                )}

                <div className="custom-button-container">
                  <CustomButton
                    text="Log In"
                    borderRadius="30px"
                    height="39px"
                    borderColor="none"
                    backgroundColor="var(--button-primary)"
                    width="100%"
                    fontSize="14px"
                    fontWeight="550"
                    fontFamily="inter"
                    className="custom-button"
                  />
                </div>
              </>
            ) : (
              // subMenu ----------------------------------------
              <div className="mobile-submenu">
                <span
                  style={{ borderBottom: "none" }}
                  className="spanBack"
                  onClick={() => setSubMenu(null)}
                >
                  ← Back
                </span>
                {subMenu === "mood" && (
                  <div style={{ marginTop: 20 }} className="mobilehl2 msubmenu">
                    <p onClick={() => handleMenuClick("/moods")}>View All</p>
                    {categories.moods.map((mood, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          handleMenuClick(
                            `/moods/${encodeURIComponent(mood.slug)}/${mood.id}`
                          )
                        }
                      >
                        <p>{mood.title}</p>
                      </div>
                    ))}
                  </div>
                )}
                {subMenu === "genres" && (
                  <div style={{ marginTop: 20 }} className="mobilehl2 msubmenu">
                    <p onClick={() => handleMenuClick("/genres")}>View All</p>
                    {categories.genre.map((genre, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          handleMenuClick(
                            `/genres/${encodeURIComponent(genre.slug)}/${
                              genre.id
                            }`
                          )
                        }
                      >
                        <p>{genre.title}</p>
                      </div>
                    ))}
                  </div>
                )}
                {subMenu === "usecase" && (
                  <div style={{ marginTop: 20 }} className="mobilehl2 msubmenu">
                    <p onClick={() => handleMenuClick("/categories")}>
                      View All
                    </p>
                    {categories.usecase.map((usecase, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          handleMenuClick(
                            `/categories/${encodeURIComponent(usecase.slug)}/${
                              usecase.id
                            }`
                          )
                        }
                      >
                        <p>{usecase.title}</p>
                      </div>
                    ))}
                  </div>
                )}
                {/* Show Occasions for Selected Category */}
                {subMenu === "occasions" && selectedOccasionCategory && (
                  <div style={{ marginTop: 20 }} className="mobilehl2 msubmenu">
                    {occasionsItems[selectedOccasionCategory]?.map(
                      (occasion, index) => (
                        <div
                          key={index}
                          onClick={() => handleMenuClick(occasion.link)}
                        >
                          <p>{occasion.title}</p>
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <RequestSong
        isOpen={isRequestOpen}
        onClose={() => setIsRequestOpen(false)}
      />
    </div>
  );
};

export default Header;
