import React, { useState, useEffect } from 'react';
import config from "../../utils/config";
import randomDefaultImage from "../../utils/RandomDefaultImage";


// const ImgOnError = (type, divId, id) => {
//   //  ALLOW TYPE TO COME IN ANY CASE, RMEOVE DEVELOPER OVERHEAD
//   type = type.toLowerCase();

//   //  SET BASE FOLDER PATH FOR THE IMAGES
//   const awsBaseURL = `${config.s3imgUrl}web/${type}/`;

//   const img = new Image();

//   img.onload = function() {
//       document.getElementById(divId).style.backgroundImage = `url(${imageUrl})`;
//   };

//   img.onerror = function() {
//       document.getElementById(divId).style.backgroundImage = `url(${defaultImageUrl})`;
//   };

//     img.src = imageUrl; // Start loading the image

//   console.log("Hi");
// };

const BgImageDiv = ({ type, id, divClass, src= "" }) => {
  const [backgroundImage, setBackgroundImage] = useState('');

  // ALLOW TYPE TO COME IN ANY CASE, REMOVE DEVELOPER OVERHEAD
  type = type.toLowerCase();

  let defaultType = '';
  let extension = "";

  //  SWITCH CASE WAS PREFERRED INSTEAD OF THE IF-ELSE CLAUSES
  switch (type) {
    case "mood":
        defaultType = "square";
        extension = ".webp";
      break;
    case "genre":
        defaultType = "genre";
        extension = ".webp";
      break;
    case "track":
        defaultType = "track";
        extension = ".webp";
      break;
    case "pack":
        defaultType = "pack";
        extension = ".webp";
      break;
    case "cat":
        defaultType = "square";
        extension = ".webp";
      break;
    case "language":
        defaultType = "pack";
        extension = ".webp";
      break;
    default:
        defaultType = "track";
        extension = ".webp";
    }

  useEffect(() => {
    const img = new Image();
    let imageUrl = '';
    if(src === '') {
      const awsBaseURL = `${config.s3imgUrl}web/${type}s`;
      imageUrl = awsBaseURL + '/' + id + extension;
    }
    else {
      imageUrl = src;
    }

    img.onload = () => {
        setBackgroundImage(imageUrl);
    };

    img.onerror = () => {
        setBackgroundImage(randomDefaultImage(defaultType));
    };

    img.src = imageUrl; // Start loading the image

    // Cleanup function to avoid memory leaks
    return () => {
        setBackgroundImage(''); // Reset on unmount
    };
  }, [id]);

    return (
      <div
        className={divClass}
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
        {/* You can add additional content inside the div if needed */}
        </div>
    );
};

export default BgImageDiv;

// export default ImgOnError;
